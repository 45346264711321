import React, { SVGProps } from 'react';

const MainHeart: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 576"
      fill="currentColor"
      {...props}
    >
      <ellipse cx="28.18" cy="130.829" fill="#8FCBFF" rx="22.726" ry="21.951" />
      <ellipse cx="285.441" cy="45.658" fill="#287AC3" rx="47.27" ry="45.658" />
      <ellipse
        cx="136.357"
        cy="63.219"
        fill="#54A0E3"
        rx="36.362"
        ry="35.122"
      />
      <path
        fill="#ED1A3B"
        fillRule="evenodd"
        d="M366.118 333.597h-60.047v57.999h-36.144v-57.999h-60.042v-34.912h60.042v-57.999h36.144v57.999h60.047v34.912Zm79.966-196.912c-123.402-45.398-158.085 74.778-158.085 74.778S253.321 91.287 129.92 136.685c-52.183 19.199-78.085 86.765-60.538 147.451 24.13 83.452 106.574 144.539 218.617 210.411 112.043-65.872 194.486-126.959 218.617-210.411 17.546-60.686-8.356-128.252-60.532-147.451Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="M358.972 574.461c-31.762-5.35-46.2-41.238-25.884-65.421 6.813-8.098 16.512-16.361 30.254-24.053 56.764-31.768 63.658-64.055 87.532-59.439 23.874 4.611 10.079 47.655-15.384 62.001 0 0 70.284 7.427 88.853-25.881 18.569-33.309 5.048-80.191 36.874-83.261 31.832-3.076 7.427 103.504-27.584 129.127-29.156 21.332-105.744 78.536-174.661 66.927Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="M498.027 477.171s27.631-29.155 20.777-61.843c-6.854-32.688 2.144-43.242 10.067-44.687 13.077-2.387 17.354 9.307 17.354 9.307s-11.283 2.709-13.497 29.381c-2.215 26.671-8.566 56.865-34.701 67.842Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="M489.239 477.171s30.283-29.133 25.381-56.465c-5.568-31.045-3.254-36.825 0-43.241 0 0-15.425-10.966-23.78 4.656-9.74 18.217 25.387 35.041-1.601 95.05ZM217.031 574.461c31.762-5.35 46.194-41.238 25.878-65.421-6.807-8.098-16.506-16.361-30.248-24.053-56.764-31.768-63.659-64.055-87.533-59.439-23.874 4.611-10.079 47.655 15.379 62.001 0 0-70.284 7.427-88.853-25.881-18.563-33.309-5.042-80.191-36.874-83.261-31.826-3.076-7.427 103.504 27.59 129.127 29.15 21.332 105.738 78.536 174.661 66.927Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="M77.97 477.171s-27.63-29.155-20.777-61.843c6.854-32.688-2.138-43.242-10.061-44.687-13.077-2.387-17.354 9.307-17.354 9.307s11.277 2.709 13.492 29.381c2.22 26.671 8.571 56.865 34.7 67.842Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFFFE"
        fillRule="evenodd"
        d="M86.764 477.171s-30.284-29.133-25.381-56.465c5.568-31.045 3.254-36.825 0-43.241 0 0 15.419-10.966 23.774 4.656 9.74 18.217-25.381 35.041 1.607 95.05Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default MainHeart;
