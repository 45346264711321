import { StatusType } from 'types';

const statusStyles: Record<
  string,
  {
    borderColor: string;
    bgColor: string;
    textColor: string;
  }
> = {
  REQUESTED: {
    borderColor: 'border-orange-300',
    bgColor: 'bg-orange-100',
    textColor: 'text-orange-950',
  },
  BOOKED: {
    borderColor: 'border-green-300',
    bgColor: 'bg-green-100',
    textColor: 'text-green-950',
  },
};

const StatusLabel: React.FC<{ status: StatusType | undefined }> = ({
  status,
}) => {
  const styles = statusStyles[status?.status_type ?? ''];

  return styles ? (
    <span
      className={`ms-3 rounded-lg border-2 ${styles.borderColor} ${styles.bgColor} p-1 ${styles.textColor} md:p-2 md:text-base`}
    >
      {status?.name}
    </span>
  ) : null;
};

export default StatusLabel;
