import { ErrorMessage, Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';

import { NextStepIcon } from 'components/svgs';
import callbackImg from 'img/callbackImg.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import React from 'react';
import request from 'request';
import { useAuth } from '../auth/AuthProvider';

import { CustomFormikPhoneInput } from '../components/InternationalPhoneNumber';
import { isValidPhoneNumber } from 'react-phone-number-input';

interface FormFields {
  name: string;
  phone: string;
  notes: string;
  terms: boolean;
  captcha: string;
}

export default function Callback() {
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const [formError, setFormError] = React.useState<string>('');
  const { token } = useAuth();
  const isLoggedIn = !!token;

  const navigate = useNavigate();
  const CallbackSchema = Yup.object().shape({
    name: Yup.string().required('Εισάγετε το ονοματεπώνυμο σας'),
    phone: Yup.string()
      .required('Εισάγετε τον αριθμό σας')
      .test('phone', 'Εισάγετε έγκυρο αριθμό', (value) => {
        return isValidPhoneNumber(value);
      }),

    notes: Yup.string().required('Περιγράψτε το θέμα σας'),
    terms: Yup.boolean().oneOf(
      [true],
      'Παρακαλώ επιβεβαίωστε την αποδοχή των όρων ιδιωτικής πολιτικής',
    ),
  });

  const handleSubmit = async (values: FormFields) => {
    try {
      const captcha = captchaRef.current?.getValue() || '';

      if (captcha === '') {
        setFormError('Παρακαλώ επιβεβαιώστε ότι δεν είστε Robot.');
        return;
      }
      values.captcha = captcha;
      captchaRef.current?.reset();

      const response = isLoggedIn
        ? await request.post(
            `${process.env.REACT_APP_BASE_API_URL}/api/v1/callback/`,
            values,
          )
        : await axios.post(
            `${process.env.REACT_APP_BASE_API_URL}/api/v1/callback/`,
            values,
          );

      if (response.status !== 200) {
        if (response.status >= 500)
          setFormError(
            'Υπάρχει ένα τεχνικό πρόβλημα αυτή τη στιγμή. Παρακαλούμε προσπαθήστε αργότερα',
          );
        else {
          setFormError('Κάτι πήγε στραβά. Παρακαλώ, προσπαθήστε αργότερα');
          return;
        }
      }

      navigate('/confirmationmsg');
    } catch (err) {
      setFormError('Κάτι πήγε στραβά. Παρακαλώ, προσπαθήστε αργότερα');
      console.log(err);
    }
  };

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:pb-8 md:pt-20 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-2xl rounded-3xl border border-gray-100 bg-gray-50 px-4 py-10 text-center xl:mb-60 xl:px-14 2xl:px-20"
      >
        <h1 className="mb-10 px-8 text-center text-2xl font-bold md:text-3xl lg:text-4xl">
          Σας καλούμε δωρεάν!
        </h1>
        <Formik
          initialValues={{
            name: '',
            phone: '',
            notes: '',
            terms: false,
            captcha: '',
          }}
          validationSchema={CallbackSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="relative mx-auto max-w-md text-left">
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label
                    htmlFor="phone"
                    className="mb-1.5 block text-sm text-gray-900 md:text-base"
                  >
                    ΤΗΛΕΦΩΝΟ ΕΠΙΚΟΙΝΩΝΙΑΣ
                  </label>
                  <CustomFormikPhoneInput name="phone" />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="callback-phone-error"
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="mb-1.5 block text-sm text-gray-900 md:text-base"
                  >
                    ΟΝΟΜΑΤΕΠΩΝΥΜΟ
                  </label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                    data-test="callback-fullname"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="callback-fullname-error"
                  />
                </div>
                <div>
                  <label
                    htmlFor="notes"
                    className="mb-1.5 block text-sm text-gray-900 md:text-base"
                  >
                    ΛΕΠΤΟΜΕΡΕΙΕΣ ΘΕΜΑΤΟΣ
                  </label>
                  <Field
                    id="notes"
                    name="notes"
                    as="textarea"
                    rows={6}
                    className="block w-full resize-y rounded-xl border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 md:text-xl"
                    data-test="callback-message"
                  />
                  <ErrorMessage
                    name="notes"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="callback-message-error"
                  />
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-row justify-between">
                    <Field
                      id="processing"
                      type="checkbox"
                      name="terms"
                      className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                      data-test="callback-terms"
                    />
                    <label
                      htmlFor="processing"
                      className="ml-2 text-xs text-gray-900 md:text-sm"
                    >
                      Συναινώ για την επεξεργασία των στοιχείων μου σύμφωνα με
                      την πολιτική απορρήτου και προστασίας προσωπικών
                      δεδομένων.
                    </label>
                  </div>
                  <ErrorMessage
                    name="terms"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="callback-terms-error"
                  />
                </div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? 'sitekey'}
                  ref={captchaRef}
                />
                <div className="w-full text-center">
                  <button
                    type="submit"
                    data-test="callback-submit-button"
                    disabled={isSubmitting}
                    className="inline-flex items-center rounded-full border border-primary bg-primary px-6  py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                  >
                    {isSubmitting ? 'Αναμονη...' : 'Υποβολή'}
                    <NextStepIcon className="ml-2.5 h-6 w-6" />
                  </button>
                  {formError && (
                    <div
                      className="mt-2 text-xs text-red-600"
                      data-test="callback-general-error"
                    >
                      {formError}
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <img
          src={callbackImg}
          alt="callbackImg"
          className="absolute bottom-[-10rem] left-0 z-10 block w-28 lg:bottom-[-190px] lg:left-[-170px]
          lg:w-72"
        />
      </div>
    </main>
  );
}
