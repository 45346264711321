import { Outlet } from 'react-router-dom';

import Stepper from './components/Stepper';
import InstructionsModal from './components/InstructionsModal';
import RemovalModal from './components/RemovalModal';
import AddAttachmentModal from './components/AddAttachmentModal';
import { AppointmentType } from 'types';
import { useCancelAppointment } from 'api';
import useStore from 'store';

export default function New() {
  const {
    isInstructionsModalOpen,
    setIsInstuctionsModalOpen,
    removalModalState,
    setRemovalModalState,
    deleteAppointment,
    addAttachmentModalState,
    setAddAttachmentModalState,
  } = useStore();

  const { mutateAsync: cancelAppointment } = useCancelAppointment();

  const handleDeleteAppointment = async (
    appointment: AppointmentType,
  ): Promise<void> => {
    try {
      const { appointment: deletedAppointment } = await cancelAppointment(
        appointment!,
      );

      deleteAppointment(appointment?.id || '');
      deleteAppointment(deletedAppointment?.id);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
        <Stepper />

        <Outlet />
      </main>

      <InstructionsModal
        open={isInstructionsModalOpen}
        onClose={() => {
          setIsInstuctionsModalOpen(false);
        }}
      />

      <RemovalModal
        open={removalModalState.isOpen}
        onClose={() => {
          setRemovalModalState({ isOpen: false, appointment: undefined });
        }}
        onAppDelete={() => {
          handleDeleteAppointment(removalModalState.appointment!);
        }}
        service={removalModalState?.appointment?.service!}
        date={removalModalState?.appointment?.start!}
        time={removalModalState?.appointment?.time!}
      />

      <AddAttachmentModal
        open={addAttachmentModalState.isOpen}
        onClose={() => {
          setAddAttachmentModalState({ isOpen: false, appointment: undefined });
        }}
        appointment={addAttachmentModalState.appointment!}
      />
    </>
  );
}
