import axios from 'axios';
import { get } from 'lodash';

import getToken from './utils/getToken';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 50000,
});

const requestFailed = (error: any) => {
  const status = get(error, 'response.status');

  if (status === 401) {
    console.log('Login required because session is expired. Redirecting...');
    // Remove the token from request
    request.defaults.headers.common.Authorization = null;
    localStorage.removeItem('token');
    window.location.href = '/login'; // Redirect to the login page
    return Promise.reject(error); // Reject the promise to prevent further execution
  }

  throw error;
};

request.interceptors.request.use(
  (config) => {
    if (config.headers && getToken('token') !== null) {
      config.headers.Authorization = `Bearer ${getToken('token')}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

request.interceptors.response.use((response) => response, requestFailed);

axios.interceptors.response.use((response) => response, requestFailed);

export default request;
