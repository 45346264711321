import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import React from 'react';
import { Field } from 'formik';

export default function InternationalPhoneNumber({
  field,
  form,
  ...props
}: any) {
  const { name, value, onBlur, onFocus } = field;
  const { setFieldValue } = form;

  return (
    <PhoneInput
      name={name}
      value={value}
      onChange={(value) => setFieldValue(name, value)}
      onBlur={onBlur}
      onFocus={onFocus}
      defaultCountry="GR"
      {...props}
      className="focus-within:border-5 block h-16 w-full rounded-full border border-gray-400 bg-white px-4 text-lg text-gray-900 focus-within:border-blue-500 focus-within:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
      numberInputProps={{
        className:
          'border-none outline-none focus:ring-0 text-lg text-gray-900 md:text-xl h-full rounded-full w-full',
      }}
    />
  );
}

interface OtherProps {
  name: string;
}

export function CustomFormikPhoneInput(props: OtherProps) {
  return (
    <Field
      className="focus-within:border-5 block w-full rounded-full border border-gray-400 bg-white py-2 text-lg text-gray-900 focus-within:border-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
      component={InternationalPhoneNumber}
      {...props}
    />
  );
}
