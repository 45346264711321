import man from 'img/man.svg';
import printer from 'img/printer.svg';
import mail from 'img/mail.png';
import { useAuth } from 'auth/AuthProvider';

export default function ReservationSuccessButtons() {
  const { token } = useAuth();
  const isLoggedIn = !!token;

  return (
    <div
      className={`grid w-full grid-cols-1 gap-3 print:hidden md:grid-cols-2 lg:grid-cols-${
        isLoggedIn ? '2' : '3'
      }`}
    >
      <button
        onClick={() => window.print()}
        type="button"
        className="flex flex-row items-center justify-center space-x-6 rounded-xl border border-gray-200 bg-gray-50 px-4 py-8 text-left text-lg font-medium transition-all hover:border-blue-600 hover:bg-blue-100 md:px-6 lg:px-8 lg:text-xl xl:px-14"
      >
        <img src={printer} alt="printer" />
        <p>Εκτύπωσε τις πληροφορίες για το ραντεβού σου</p>
      </button>

      <button
        onClick={() => window.open('https://gmail.com')}
        type="button"
        className="flex flex-row items-center justify-center space-x-6 rounded-xl border border-gray-200 bg-gray-50 px-4 py-8 text-left text-lg font-medium transition-all hover:border-blue-600 hover:bg-blue-100 md:px-6 lg:px-8 lg:text-xl xl:px-14"
      >
        <img src={mail} alt="mail" />
        <p>Άνοιξε το mail σου για το μήνυμα επιβεβαίωσης</p>
      </button>

      {!isLoggedIn && (
        <a
          href="/login"
          className="flex flex-row items-center justify-center space-x-6 rounded-xl border border-gray-200 bg-gray-50 px-4 py-8 text-left text-lg font-medium transition-all hover:border-blue-600 hover:bg-blue-100 md:px-6 lg:px-8 lg:text-xl xl:px-14"
        >
          <img src={man} alt="man" />
          <p>Συνδέσου για να δεις τα ραντεβού σου</p>
        </a>
      )}
    </div>
  );
}
