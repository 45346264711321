import { decrypt } from 'utils/crypto';

/**
 * @param token the name of the local storage item
 * @returns the token from localStorage
 */
export default function getToken(token: string): Object | string | undefined {
  const tokenString = localStorage.getItem(token);
  const tokenDec = tokenString && decrypt(tokenString);
  const userToken = tokenDec && JSON.parse(tokenDec);

  return userToken;
}
