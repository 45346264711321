import { AppointmentType } from 'types';
interface Props {
  reservedApps: Array<AppointmentType>;
}
export default function ReservationsConfirmMessage({ reservedApps }: Props) {
  const count = reservedApps.length;
  const [bookedApps, requestedApps] = reservedApps.reduce(
    ([booked, requested]: [AppointmentType[], AppointmentType[]], app) => {
      if (app.status?.status_type === 'BOOKED') booked.push(app);
      if (app.status?.status_type === 'REQUESTED') requested.push(app);
      return [booked, requested];
    },
    [[], []],
  );
  console.log(bookedApps);
  console.log(requestedApps);
  let message;
  if (count === 1) {
    message = bookedApps.length ? (
      <p>Η κράτηση σας έχει επιβεβαιωθεί!</p>
    ) : (
      <div>
        <p>Η κράτηση σας έχει καταχωρηθεί!</p>
        <p className="pt-2 text-base">
          Θα ενημερωθείτε για την επιβεβαίωσή της μόλις γίνει αποδεκτή!
        </p>
      </div>
    );
  } else if (bookedApps.length && requestedApps.length) {
    message = (
      <div>
        <p>Κάποιες από τις κρατήσεις σας έχουν επιβεβαιωθεί!</p>
        <p> Για τις υπόλοιπες θα ενημερωθείτε μέσω email.</p>
      </div>
    );
  } else if (requestedApps.length) {
    message = (
      <div>
        <p>Οι κρατήσεις σας έχουν καταχωρηθεί!</p>
        <p className="pt-2 text-base">
          Θα ενημερωθείτε για την επιβεβαίωσή τους μόλις γίνουν αποδεκτές!
        </p>
      </div>
    );
  } else {
    message = <p>Οι κρατήσεις σας έχουν επιβεβαιωθεί!</p>;
  }
  return (
    <h1
      className={`mx-auto mb-6 max-w-xs rounded-3xl bg-green-400/25 p-5 text-center text-xl font-bold text-green-700 sm:mb-10 sm:max-w-md sm:text-2xl md:max-w-lg md:p-10 md:text-3xl lg:max-w-xl`}
    >
      {message}
    </h1>
  );
}
