import React, { SVGProps } from 'react';

const HomeCalendarIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      fill="currentColor"
      {...props}
      aria-hidden="true"
    >
      <g clipPath="url(#a)">
        <circle cx="40" cy="40" r="40" fill="#287AC3" />
        <mask
          id="b"
          width="40"
          height="40"
          x="20"
          y="20"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M20 20h40v40H20z" />
        </mask>
        <g mask="url(#b)">
          <path
            fill="#F9FAFB"
            fillRule="evenodd"
            d="M30 26v2h-2a4 4 0 0 0-4 4v20a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V32a4 4 0 0 0-4-4h-2v-2a2 2 0 1 0-4 0v2H34v-2a2 2 0 1 0-4 0Zm0 10a2 2 0 0 0 2 2h16a2 2 0 1 0 0-4H32a2 2 0 0 0-2 2Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h80v80H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeCalendarIcon;
