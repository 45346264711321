import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import useStore from 'store';
import UserReservationPreview from './UserReservationPreview';
import Appointment from '../../components/Appointment';
import NavigationButtons from '../../components/NavigationButtons';
import { AppointmentType, StatusTypeEnum, VisitorInfoApiType } from 'types';
import { useFinalizeAppointments, useConfirmAppointments } from 'api';
import { useQuery } from '@tanstack/react-query';
import { ExclamationMark } from 'components/svgs';
import request from 'request';
import ErrorMessage from 'views/reservation/components/ErrorMessage';
import { mapApiContactToVisitorInfo, resetSuccessfulMessage } from 'helpers';
import SuccesfulEmailResentMessage from 'views/reservation/components/SuccefulEmailResentMessage';
import { isEmpty } from 'lodash';
import useStoreServices from 'storeServices';
import useScrollTo from 'hooks/useScrollTo';

export default function ReservationsPreview() {
  const { scrollToElement } = useScrollTo();

  const [emailVerification, setEmailVerification] = useState(true);
  const [userEmailVerified, setUserEmailVerified] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [userContactId, setUserContactId] = useState<string | undefined>();
  const [showDifferentDaysWarning, setShowDifferentDaysWarning] =
    useState(false);
  const navigate = useNavigate();
  const {
    appointments,
    updateAppointments,
    clearAppointments,
    setRemovalModalState,
    setAddAttachmentModalState,
    setIsAppsExpired,
    visitorInfo,
    visitorIDs,
    setVisitorIDs,
    setCurrentAppointment,
    setIsDatetimeRangeOk,
    removalModalState,
    addAttachmentModalState,
  } = useStore();

  const { availableArrivalPolicies, availableInsuranceProviders } =
    useStoreServices();

  const { isOpen } = removalModalState || addAttachmentModalState;

  const { mutateAsync: finalizeAppointments } = useFinalizeAppointments();
  const { mutateAsync: confirmAppointments } = useConfirmAppointments();

  const handleAppsExpired = () => {
    setIsAppsExpired(true);
    clearAppointments();
    navigate('/');
  };

  const handleDateRangeOverlap = () => {
    setIsDatetimeRangeOk(false);
    navigate('/');
  };

  const handleErrorMessage = (error: any) => {
    try {
      const { status } = error.response;
      if (status >= 500) {
        setErrorMessage(
          'Υπάρχει ένα τεχνικό πρόβλημα αυτήν την στιγμή. Παρακαλούμε προσπαθήστε ξανά αργότερα.',
        );
      } else if (status >= 400) {
        if (error.response.data.hasOwnProperty('error')) {
          let errors = error.response.data.error;
          Object.keys(errors).forEach((key) => {
            if (key === 'status_id')
              errors.status_id[0] ===
              'Πρέπει να επιβεβαιώσεις το email σου πρώτα'
                ? setErrorMessage(
                    'Πρέπει να επιβεβαιώσετε το email σας για να συνεχίσετε!',
                  )
                : setErrorMessage(
                    'Υπήρξε πρόβλημα με την επιβεβαίωση της κράτησής σας.',
                  );
            else {
              errors[key].length > 0
                ? setErrorMessage(errors[key][0])
                : setErrorMessage(
                    'Υπήρξε πρόβλημα με την επιβεβαίωση της κράτησής σας.',
                  );
            }
          });
        } else {
          let errorMsg = 'Υπήρξε πρόβλημα με την επιβεβαίωση της κράτησής σας.';
          Object.keys(error.response.data).forEach((key) => {
            errorMsg += '\n';
            errorMsg += Array.isArray(error.response.data[key])
              ? error.response.data[key][0]
              : error.response.data[key] || '';
          });
          setErrorMessage(errorMsg);
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Υπήρξε πρόβλημα με την επιβεβαίωση της κράτησής σας.');
    }
  };

  const handleEmailVerification = async () => {
    const requestObj = {
      appointments: appointments,
      ids: visitorIDs!,
    };
    try {
      await finalizeAppointments(requestObj);
      setUserEmailVerified(true);
      const res = await refetchAppointments();
      updateAppointments(res.data as AppointmentType[]);
      setErrorMessage('');
      res.isSuccess && navigate('/new/reservations-confirm');
    } catch (error: any) {
      console.log(error);
      if (
        error.response.data.error.status_id[0] === 'Reservation expired' ||
        error.response.data.error.status_id[0] === 'Η κράτηση έληξε'
      ) {
        handleAppsExpired();
      } else handleErrorMessage(error);
    }
  };

  const sendReservationVerification = async () => {
    if (emailSent) return;
    await request
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/contacts/${userContactId}/send_email_confirmation_url/`,
      )
      .then((results) => {
        if (results.status === 200) {
          setEmailSent((prev) => !prev);
          setErrorMessage('');
          resetSuccessfulMessage(setEmailSent);
          return results;
        } else handleErrorMessage(results);
      })
      .catch((error) => {
        console.log(error);
        setEmailSent(false);
        handleErrorMessage(error);
      });
  };

  const handleConfirmAppointments = async () => {
    try {
      const response = await confirmAppointments({
        visitorInfo: visitorInfo!,
        appointments: appointments,
      });
      setVisitorIDs(response);
      setUserContactId(response.primaryContact);
      setEmailVerification(false);
      setErrorMessage('');
    } catch (error: any) {
      console.log(error);
      const { status } = error.response;
      if (status === 409) {
        handleDateRangeOverlap();
      } else handleErrorMessage(error);
    }
  };

  const fetchAppointments = async () => {
    const { data } = await request.get('/api/v1/reservations/');
    return data
      .filter(({ start, status, id }: AppointmentType) => {
        const now = new Date();
        return (
          start &&
          new Date(start) > now &&
          status &&
          [StatusTypeEnum.BOOKED, StatusTypeEnum.REQUESTED].includes(
            status.status_type,
          ) &&
          appointments.find((localApp) => localApp.id === id)
        );
      })
      .map((appointment: { primary_contact: VisitorInfoApiType }) => ({
        ...appointment,
        primary_contact: appointment.primary_contact
          ? mapApiContactToVisitorInfo(
              appointment.primary_contact,
              availableInsuranceProviders,
            )
          : null,
      }))
      .sort(
        (a: AppointmentType, b: AppointmentType) =>
          new Date(a.start!).getTime() - new Date(b.start!).getTime(),
      );
  };

  const {
    data,
    isFetching,
    refetch: refetchAppointments,
  } = useQuery<any, Error>({
    queryKey: ['userAppointmentsData'],
    staleTime: 0,
    queryFn: fetchAppointments,
    enabled: false,
    onError: (error: any) => {
      const { status } = error.response;
      if (status === 406) {
        setUserEmailVerified(false);
      } else handleErrorMessage(error);
    },
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (!emailSent) scrollToElement('button-forward');
  }, [emailSent, scrollToElement]);

  useEffect(() => {
    console.log(appointments);
    if (isEmpty(appointments) && !isOpen) {
      navigate('/');
    }
  }, [appointments, navigate, isOpen]);

  useEffect(() => {
    const checkAppointmentsOnDifferentDays = () => {
      const appointmentDays = appointments.map((appointment) =>
        new Date(appointment.start!).getDate(),
      );

      if (new Set(appointmentDays).size > 1) {
        // Overlapping appointments found
        setShowDifferentDaysWarning(true);
      } else {
        setShowDifferentDaysWarning(false);
      }
    };

    checkAppointmentsOnDifferentDays();
  }, [appointments]);

  return (
    <>
      <UserReservationPreview user={visitorInfo} />

      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="print:hidden"
      >
        <h2 className="mb-6 text-center text-2xl font-bold">
          Προγραμματισμένα Ραντεβού
        </h2>
        {showDifferentDaysWarning && (
          <div
            id="DateTimeError"
            className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl"
          >
            <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
              <ExclamationMark />
            </div>
            <p className="text-left text-xs md:text-base">
              Προειδοποίηση: Υπάρχουν προγραμματισμένα ραντεβού σε διαφορετική
              ημέρα.
            </p>
          </div>
        )}
      </div>

      {appointments?.map((appointment: AppointmentType) => (
        <Appointment
          key={appointment?.id}
          appointment={appointment}
          policies={availableArrivalPolicies}
          onDelete={() => {
            setRemovalModalState({ isOpen: true, appointment });
          }}
          onAddAttachment={() => {
            setAddAttachmentModalState({ isOpen: true, appointment });
          }}
          onEdit={() => {
            setCurrentAppointment(appointment);
            navigate('/new/reservations');
          }}
        />
      ))}

      {!emailVerification && (
        <div className="mx-auto mt-10 flex flex-col items-center">
          <p className="mb-5 max-w-6xl text-center text-xl font-bold md:text-3xl">
            Σας έχει σταλεί ένα email επιβεβαίωσης στην διεύθυνση{' '}
            {visitorInfo?.email}
          </p>
          {emailSent ? (
            <SuccesfulEmailResentMessage />
          ) : (
            <div>
              <p className="max-w-2xl py-1.5 text-center text-base md:text-xl">
                Εάν δεν βρίσκεται το σχετικό email, ελέγξτε την Ανεπιθύμητη
                Αλληλογραφία σας ή πατήστε το κουμπί για την επαναποστολή.
              </p>
            </div>
          )}
          <button
            onClick={sendReservationVerification}
            className=" my-8 inline-flex items-center rounded-full border border-primary bg-primary px-4 py-2.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 sm:px-6 sm:py-3.5"
          >
            Επαναποστολή email
          </button>
        </div>
      )}

      {errorMessage! && (
        <div className="mb-12 px-4">
          <ErrorMessage message={errorMessage} />
        </div>
      )}

      {!userEmailVerified && (
        <div className="mx-auto mt-10 flex max-w-xl flex-col items-center justify-center rounded-lg bg-red-100 py-5">
          <div className="flex">
            <span className="me-3 h-8 w-8 text-red-600">
              <ExclamationMark />
            </span>
            <span className="text-lg">
              Δεν έχετε επιβεβαιώσει{' '}
              {data.length > 1 ? 'τις κρατήσεις' : 'την κράτησή'} σας!!!
            </span>
          </div>
          <p>Ακολουθήστε τον σύνδεσμο στο σχετικό email</p>
        </div>
      )}

      <NavigationButtons
        isForwardButtonDisabled={appointments.length === 0}
        onPreviousStep={() => {
          navigate(-1);
        }}
        isPageUserReservationPreview={{ emailVerification, isFetching }}
        onForwardStep={() => {
          emailVerification
            ? handleConfirmAppointments()
            : handleEmailVerification();
        }}
      />
    </>
  );
}
