import { TickIcon } from 'components/svgs';

export default function SuccesfulEmailResentMessage() {
  return (
    <div className="flex flex-col items-center">
      <p className="text-base md:text-xl">
        Σας έχει σταλθεί ένα νέο email επιβεβαίωσης!
      </p>
      <TickIcon className="h-10 w-10 text-green-600" />
    </div>
  );
}
