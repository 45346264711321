import { useEffect, useState } from 'react';
import { isSameDay } from 'date-fns';
import { Calendar } from 'react-calendar';
import { maxDate, minDate } from 'utils/consts';
import { TickIcon } from 'components/svgs';
import { AppointmentType, AvailabilityHours, Slots } from 'types';
import TimePicker from './TimePicker';
import clock from 'img/clock.png';
import { useAvailableSlots } from 'api';
import useStore from 'store';
import { Loading } from 'components/Loader';

// react-calendar value type
type ValuePiece = Date | null;

interface Props {
  currentAppointment?: AppointmentType;
  availabilityHours: AvailabilityHours;
  onStateChange: () => void;
  onMonthChange: (date: Date | null) => void;
}

export default function DateTimeSelect({
  currentAppointment,
  availabilityHours,
  onStateChange,
  onMonthChange,
}: Props) {
  const { setCurrentAppointment } = useStore();
  const [selectedDate, setSelectedDate] = useState(
    new Date(Object.keys(availabilityHours)[0]),
  );

  const [selectedTime, setSelectedTime] = useState<string | undefined>(
    new Date(currentAppointment?.start!).toLocaleTimeString()!,
  );

  const [selectedDateHourSlots, setSelectedDateHourSlots] = useState<
    string[] | undefined
  >([]);

  const extractTime = (timestamp: string) => {
    const date = new Date(timestamp);

    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const {
    data: slotsAvailable,
    isInitialLoading,
    isFetched,
  } = useAvailableSlots(currentAppointment ?? undefined, selectedDate);

  function tileClassName({ date }: { date: Date }): string {
    const hasAvailability = Object.keys(availabilityHours).some((availDate) =>
      isSameDay(new Date(availDate), date),
    );

    return hasAvailability ? 'available' : '';
  }

  const handleChangeDate = (value: ValuePiece | [ValuePiece, ValuePiece]) => {
    setSelectedDate(value as Date);
    setCurrentAppointment({
      start: value as Date,
      end: value as Date,
    });

    setSelectedDateHourSlots(
      slotsAvailable?.map((slot: Slots) => extractTime(slot.start)),
    );
    setSelectedTime(undefined);
  };

  const handleSelectTime = (time: string) => {
    setSelectedTime(time);
    setCurrentAppointment({
      time: time ?? undefined,
    });
  };

  useEffect(() => {
    if (isFetched) {
      setSelectedDateHourSlots(
        slotsAvailable?.map((slot: Slots) => extractTime(slot.start)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, slotsAvailable]);

  const { service, point } = currentAppointment || {};
  const { service_customizations, duration } = service || {};
  const customDuration =
    point &&
    service_customizations?.find((sc) => sc.point === point.id)?.duration;
  const finalDuration = customDuration || duration;

  return (
    <div>
      <div className="mt-6 grid max-h-[70rem] w-full grid-rows-2 space-y-5 print:hidden sm:grid-cols-12 sm:grid-rows-1 sm:space-x-5 sm:space-y-0 md:max-h-[50rem]">
        <div className="max-w-3xl rounded-lg border border-gray-400 bg-white px-4 py-6 sm:col-span-7 md:block md:px-8 lg:px-12">
          <h3 className="mb-4 text-center text-lg font-bold md:text-xl">
            Επιλέξτε Ημερομηνία
          </h3>

          <Calendar
            value={selectedDate}
            onChange={handleChangeDate}
            onClickMonth={(date) => {
              if (date.getMonth() === 0) {
                onMonthChange(date);
              }
            }}
            onActiveStartDateChange={({ activeStartDate, view }) => {
              if (view === 'month') {
                onMonthChange(activeStartDate);
              }
            }}
            tileClassName={tileClassName}
            locale="el"
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>

        {isInitialLoading || !isFetched ? (
          <div className="max-w-3xl rounded-lg border border-gray-400 bg-white px-4 py-6 sm:col-span-5 md:block md:px-8 lg:px-12">
            <div className="flex h-full flex-col justify-center space-y-6">
              <div className="mb-16 ml-10 flex w-full flex-row justify-center">
                <Loading size={120} />
              </div>
            </div>
          </div>
        ) : !Boolean(selectedDate) ? (
          <div className="max-w-3xl rounded-lg border border-gray-400 bg-white px-4 py-6 sm:col-span-5 md:block md:px-8 lg:px-12">
            <div className="flex h-full flex-col justify-between space-y-6">
              <div>
                <h3 className="mb-4 text-center text-lg font-bold md:text-xl">
                  Επιλέξτε Ώρα
                </h3>

                <p className="mx-auto mt-4 max-w-xs text-center">
                  Επιλέξτε πρώτα ημερομηνία για να εμφανιστούν οι διαθέσιμες
                  ώρες
                </p>
              </div>

              <div className="flex flex-1 flex-col items-center justify-center">
                <img src={clock} alt="an illustration of a clock" />
              </div>
            </div>
          </div>
        ) : (
          <TimePicker
            value={selectedTime}
            availableSlots={selectedDateHourSlots}
            duration={finalDuration}
            onSelectTime={handleSelectTime}
          />
        )}
      </div>

      <div className="mt-6 flex w-full flex-row justify-center print:hidden lg:justify-end">
        <button
          type="button"
          disabled={!selectedTime && selectedTime === '00:00:00'}
          onClick={onStateChange}
          className="inline-flex items-center rounded-full border border-primary bg-primary px-6 py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
        >
          <span>Αποθήκευση</span>

          <TickIcon className="ml-2.5 h-6 w-6" />
        </button>
      </div>
    </div>
  );
}
