import React, { useState, useEffect } from 'react';

import logo from 'img/logo.png';
import { ManIcon, CloseBurgerIcon, LogOutIcon } from 'components/svgs';
import { useLocation } from 'react-router-dom';

interface Props {
  onClick: () => void;
  logout: () => void;
  isLoggedIn: boolean;
}

export default function MobileMenu({ onClick, isLoggedIn, logout }: Props) {
  const handleLogout = () => {
    logout();
  };
  const location = useLocation();
  const [url, setUrl] = useState<string>('');
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const activeLinkClassname = 'text-primary';

  return (
    <div
      data-test="mobile-menu"
      className="fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-between bg-gradient-to-b from-blue-50 to-gray-150 px-4 py-7 md:py-8 lg:hidden lg:px-10"
    >
      <div className="mx-auto flex w-full max-w-screen-2xl flex-row justify-between">
        <a href="/">
          <img src={logo} className="h-7 md:h-10" alt="HHG Logo" />
        </a>

        {/* <!-- close mobile menu button --> */}
        <button
          onClick={onClick}
          type="button"
          className="z-100 ml-3 inline-flex items-center rounded-full p-2 text-sm text-gray-900 transition-all hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-200 print:hidden lg:hidden"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Close main menu</span>

          <CloseBurgerIcon className="h-6 w-6 md:h-8 md:w-8" />
        </button>
      </div>
      <div className="space-y-10">
        <ul className="flex flex-col space-y-10 text-center text-2xl font-bold">
          <li>
            <a
              href="https://www.hhg.gr/el/ανακοινώσεις/γενικές-ανακοινώσεις"
              target="_self"
              className={
                "relative transition-all hover:before:absolute hover:before:-bottom-3 hover:before:left-1/2 hover:before:h-1 hover:before:w-full hover:before:translate-x-[-50%] hover:before:rounded-full hover:before:bg-primary hover:before:content-[''] " +
                (url === '/announcements' ? activeLinkClassname : '')
              }
            >
              Ανακοινώσεις
            </a>
          </li>
          <li>
            <a
              href="/callback"
              target="_self"
              className={
                "relative transition-all hover:before:absolute hover:before:-bottom-3 hover:before:left-1/2 hover:before:h-1 hover:before:w-full hover:before:translate-x-[-50%] hover:before:rounded-full hover:before:bg-primary hover:before:content-[''] " +
                (url === '/callback' ? activeLinkClassname : '')
              }
            >
              Σε Καλούμε
            </a>
          </li>

          <li>
            <a
              href="https://www.hhg.gr/el/επικοινωνία"
              target="_self"
              className={
                "relative transition-all hover:before:absolute hover:before:-bottom-3 hover:before:left-1/2 hover:before:h-1 hover:before:w-full hover:before:translate-x-[-50%] hover:before:rounded-full hover:before:bg-primary hover:before:content-[''] " +
                (url === '/contact' ? activeLinkClassname : '')
              }
            >
              Επικοινωνία
            </a>
          </li>

          {isLoggedIn && (
            <li>
              <a
                href="/account"
                target="_self"
                className={
                  "relative transition-all hover:before:absolute hover:before:-bottom-3 hover:before:left-1/2 hover:before:h-1 hover:before:w-full hover:before:translate-x-[-50%] hover:before:rounded-full hover:before:bg-primary hover:before:content-[''] " +
                  (url === '/account' ? activeLinkClassname : '')
                }
              >
                Προφίλ
              </a>
            </li>
          )}
        </ul>

        {isLoggedIn ? (
          <button
            onClick={handleLogout}
            type="button"
            className="mt-56 inline-flex items-center rounded-full border border-primary bg-primary px-6 py-4 text-center text-base font-medium text-gray-50 transition-all hover:bg-gray-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            <span>Αποσύνδεση</span>
            <LogOutIcon className="ml-2" />
          </button>
        ) : (
          <>
            <a
              href="/login"
              className="mt-56 inline-flex items-center rounded-full border border-primary bg-gray-50 px-6 py-4 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              <span>Είσοδος Χρήστη</span>
              <ManIcon />
            </a>
          </>
        )}
      </div>
      <p className="text-xs">© Copyright 2023 HHG. All Rights Reserved</p>
    </div>
  );
}
