import { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useStore from 'store';

import { CloseIcon, CloseBurgerIcon, PrintIcon } from 'components/svgs';

interface Props {
  onClose: () => void;
  open: boolean;
}

const InstructionsModal = ({ onClose, open = false }: Props) => {
  const [isOpen, setIsOpen] = useState(open);

  const { instructionModalText } = useStore();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = (): void => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <Transition appear show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex flex-col items-center justify-center p-4 print:absolute print:h-auto"
        onClose={handleClose}
        open={isOpen}
      >
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="relative max-h-full w-full max-w-lg"
        >
          <Dialog.Panel className="relative flex max-h-full flex-col rounded-lg bg-white shadow print:block print:h-auto print:rounded-none print:shadow-none">
            <div className="flex items-center justify-between rounded-t border-b bg-gray-100 p-5">
              <Dialog.Title
                as="h3"
                className="flex-1 pl-4 text-center text-xl font-medium text-gray-900"
              >
                Οδηγίες προετοιμασίας
              </Dialog.Title>
              <button
                onClick={handleClose}
                type="button"
                className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 transition-all hover:bg-blue-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-200 print:hidden"
              >
                <CloseIcon className="h-5 w-5" />
              </button>
            </div>

            <div className="prose prose-blue mx-auto max-w-full space-y-6 overflow-y-scroll p-6 lg:prose-lg hover:prose-a:text-blue-900 prose-blockquote:border-blue-500 prose-li:marker:text-blue-900 prose-th:bg-gray-200 prose-th:p-2 prose-td:p-2 prose-img:w-full prose-img:rounded-lg print:overflow-y-visible">
              <pre className="whitespace-pre-line bg-white font-sans text-base text-black">
                {instructionModalText}
              </pre>
            </div>

            <div className="flex items-center justify-end space-x-2 rounded-b border-t border-gray-200 bg-gray-100 p-6 print:hidden">
              <button
                onClick={() => window.print()}
                type="button"
                className="inline-flex items-center rounded-full border border-primary bg-gray-50 px-4 py-2 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                <span>Εκτύπωση</span>

                <PrintIcon className="ml-2.5 h-5 w-5" />
              </button>
              <button
                onClick={handleClose}
                type="button"
                className="inline-flex items-center rounded-full border border-primary bg-primary px-4 py-2 text-center text-base font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                <span>Κλείσιμο</span>

                <CloseBurgerIcon className="ml-2.5 h-6 w-6" />
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default InstructionsModal;
