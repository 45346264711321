import { useState, useEffect } from 'react';
import cn from 'classnames';
import { ClockIcon } from 'components/svgs';
import myAppointmentsCalendar from '../../../../img/myAppointmentsCalendar.png';

interface Props {
  value?: string;
  availableSlots?: string[];
  duration?: number;
  onSelectTime: (time: string) => void;
}

const timeLabelsForDuration: { [key: number]: string } = {
  5: 'πέντε λεπτά',
  10: 'δέκα λεπτά',
  15: 'δεκαπέντε λεπτά',
  20: 'είκοσι λεπτά',
  25: 'εικοσιπέντε λεπτά',
  30: 'μισή ώρα',
  35: 'τριανταπέντε λεπτά',
  40: 'σαράντα λεπτά',
  45: 'σαρανταπέντε λεπτά',
  50: 'πενήντα λεπτά',
  55: 'πενηνταπέντε λεπτά',
  60: 'μια ώρα',
};

export default function TimePicker({
  value,
  availableSlots,
  duration = 30,
  onSelectTime,
}: Props) {
  const [selectedTime, setSelectedTime] = useState<string | undefined>(value);

  let timeSlots: string[] = [];
  availableSlots?.map((slot) => timeSlots.push(slot));

  const handleClick = (time: string) => {
    onSelectTime(time);
    setSelectedTime(time);
  };

  useEffect(() => {
    setSelectedTime(value);
  }, [value]);

  return (
    <div className="max-w-3xl rounded-lg border border-gray-400 bg-white px-4 py-6 sm:col-span-5 md:block md:px-8 lg:px-12">
      {timeSlots.length === 0 ? (
        <div className="flex h-full flex-col items-center justify-center text-center">
          <div className="grid w-full  items-center ">
            <img
              className="mx-auto"
              src={myAppointmentsCalendar}
              alt="myAppointmentsCalendar"
            />
            <p className="mt-10">
              Δεν υπάρχουν διαθέσιμα ραντεβού για την επιλεγμένη ημερομηνία
            </p>
          </div>
        </div>
      ) : (
        <div className="flex h-full flex-col space-y-6">
          <div>
            <h3 className="mb-4 text-center text-lg font-bold md:text-xl">
              Επιλέξτε Ώρα
            </h3>
            <div className="mx-auto flex max-w-full flex-row items-center space-x-3 rounded-lg bg-blue-100 p-6">
              <div className="h-8 w-8 flex-shrink-0 text-blue-900 print:hidden lg:h-12 lg:w-12">
                <ClockIcon />
              </div>

              <p className="text-left text-xs tracking-tight  lg:text-base">
                Διαθέσιμα ραντεβού ανά {timeLabelsForDuration[duration]} λόγω
                μέσου χρόνου διαδικασίας υπηρεσίας στα {duration}’.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 overflow-y-scroll lg:gap-4">
            {timeSlots.map((time) => (
              <button
                type="button"
                key={time}
                className={cn(
                  'flex h-14 w-full flex-col items-center justify-center rounded-md  px-1 py-2 text-xl font-bold text-gray-900 transition-all hover:bg-blue-500 hover:text-white active:bg-blue-900 active:text-white disabled:cursor-not-allowed disabled:text-gray-400 disabled:hover:bg-gray-100',
                  selectedTime === time
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100',
                )}
                onClick={() => handleClick(time)}
              >
                {time}

                <span className="text-[8px] font-normal leading-tight">
                  {selectedTime === time && 'επιλεγμένο ραντεβού'}
                </span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
