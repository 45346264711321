import React from 'react';
import { useRef } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { PointsType } from 'types';
import { debounce, isEmpty } from 'lodash';

import { VectorIcon } from 'components/svgs';

export interface SelectProps {
  title: string;
  name: string;
  servicesIcon: string;
  // TODO: better type
  serviceGrp: PointsType[];
  value: any;
  handleOnChange: (value: any) => void;
}

export const Select = React.memo(
  ({
    name,
    serviceGrp,
    servicesIcon,
    title,
    value,
    handleOnChange,
  }: SelectProps) => {
    const [query, setQuery] = React.useState('');

    const [isEditing, setIsEditing] = React.useState(false);

    const setQueryDebounced = debounce(setQuery, 500);

    const comboboxButtonRef = useRef<HTMLButtonElement>(null);

    const filteredServices =
      query === ''
        ? serviceGrp
        : serviceGrp.filter((serviceGrp) => {
            return serviceGrp.name.toLowerCase().includes(query.toLowerCase());
          });

    const handleChange = (value: any) => {
      handleOnChange(value);
    };

    return (
      <Combobox value={value} onChange={handleChange} name={name}>
        <div className="relative" data-test={`select-${name}`}>
          <Combobox.Button
            ref={comboboxButtonRef}
            className=" w-full text-center"
          >
            {({ open }) => (
              <Combobox.Input
                className="block w-full truncate  rounded-full  border-0 px-16 py-4 text-center text-gray-900 ring-1 ring-inset ring-gray-400 hover:bg-gray-50 hover:ring-gray-800 focus:ring-2 focus:ring-inset focus:ring-blue-600 xl:py-3.5 xl:text-xl"
                onChange={(event) => setQueryDebounced(event.target.value)}
                onFocus={() => setIsEditing(true)}
                onBlur={() => setIsEditing(false)}
                autoComplete="off"
                onClick={() => {
                  if (!open) comboboxButtonRef.current?.click();
                }}
                displayValue={(selection) => {
                  return (selection as PointsType)?.name;
                }}
                data-test="components-select-input"
              />
            )}
          </Combobox.Button>
          <div className="pointer-events-none absolute inset-y-0 mx-auto flex w-full items-center truncate pl-7 text-center xl:text-xl">
            <>
              <img
                src={servicesIcon}
                alt="services icon"
                className="w-5.5 h-5 flex-shrink-0"
              />
              {isEmpty(value) && !isEditing && (
                <div className="absolute inset-y-0 left-0 right-0  top-3 z-0 truncate px-16">
                  {title}
                </div>
              )}
            </>
          </div>

          <Combobox.Button className="absolute inset-y-8 right-0 flex items-center pr-7">
            <VectorIcon className="h-4 w-4" />
          </Combobox.Button>
        </div>
        <Transition
          leave="transition all ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options
            className={`absolute z-10 block max-h-60 w-full !overflow-y-auto truncate rounded-2xl bg-white  ring-gray-400 hover:ring-blue-600 focus:ring-blue-600 ${
              filteredServices.length !== 0 && 'border-2 border-blue-600'
            }`}
          >
            {filteredServices.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700 xl:text-xl">
                Δεν βρεθηκε
              </div>
            ) : (
              filteredServices.map((elem: any) => (
                <Combobox.Option
                  key={elem.id}
                  value={elem}
                  title={elem.name}
                  className={({ active }) =>
                    `relative w-full cursor-pointer select-none truncate whitespace-pre-line px-4 py-2 text-left xl:text-xl ${
                      active ? 'bg-blue-600 text-white' : 'text-gray-900'
                    }`
                  }
                >
                  {elem.name}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    );
  },
);
