import CryptoJS from 'crypto-js';

const PRIVATE_KEY = 'Hellenic Health Group Private Key';

export const encrypt = (pureText: string) => {
  const ciphertext = encodeURIComponent(
    CryptoJS.AES.encrypt(JSON.stringify(pureText), PRIVATE_KEY).toString(),
  );

  return ciphertext;
};

export const decrypt = (encryptedText: string) => {
  const bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(encryptedText),
    PRIVATE_KEY,
  );
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};
