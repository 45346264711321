import React, { SVGProps } from 'react';

const VectorIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.04853 0.462025L6 4.73416L9.95147 0.462025C10.4201 -0.0446333 11.1799 -0.0446333 11.6485 0.462025C12.1172 0.968683 12.1172 1.79014 11.6485 2.2968L6.84853 7.48631C6.3799 7.99297 5.6201 7.99297 5.15147 7.48631L0.351472 2.2968C-0.117157 1.79014 -0.117157 0.968683 0.351472 0.462025C0.820101 -0.0446332 1.5799 -0.0446332 2.04853 0.462025Z"
        fill="#111827"
      />
    </svg>
  );
};

export default VectorIcon;
