import { useCallback } from 'react';

type UseScrollTo = {
  scrollToTop: () => void;
  scrollToElement: (elementId: string) => void;
};

const useScrollTo = (): UseScrollTo => {
  const executeScroll = useCallback((elementId?: string) => {
    const element = elementId ? document.getElementById(elementId) : null;

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return {
    scrollToTop: () => executeScroll(),
    scrollToElement: (elementId) => executeScroll(elementId),
  };
};

export default useScrollTo;
