export default function Announcements() {
  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mb-10 rounded-3xl bg-gray-50 px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl ">
          Ανακοινώσεις
        </h1>
      </div>
    </main>
  );
}
