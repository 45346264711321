import React, { useState, useRef, useEffect } from 'react';
import {
  ErrorMessage,
  Formik,
  Form,
  Field,
  FormikHelpers,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Select } from '../components/Select';
import { useInsuranceProviders } from 'api';
import simpleDoctor from 'img/simple-doctor.svg';
import { InsuranceProvidersType } from 'types';
import InputField from 'components/InputField';
import { default as Error } from 'views/reservation/components/ErrorMessage';
import SuccesfulEmailResentMessage from './reservation/components/SuccefulEmailResentMessage';
import { luhn_check, resetSuccessfulMessage } from 'helpers';

import { NextStepIcon, NotVisibleIcon, VisibleIcon } from 'components/svgs';
import useStoreServices from 'storeServices';
import { isEmpty } from 'lodash';
import { CustomFormikPhoneInput } from '../components/InternationalPhoneNumber';
import { isValidPhoneNumber } from 'react-phone-number-input';

interface FormFields {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  password: string;
  has_foreign_ssrn: boolean;
  ssrn: string;
  insurance_provider: InsuranceProvidersType | null;
  tos_accepted: boolean;
  email_notifications_accepted: boolean;
  sms_notifications_accepted: boolean;
}

export default function Register() {
  const formRef = useRef<FormikProps<FormFields>>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [signedUp, setSignedUp] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const { availableInsuranceProviders, setAvailableInsuranceProviders } =
    useStoreServices();
  const { data: insuranceProviders, isFetching } = useInsuranceProviders();

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string().required('Εισάγετε το όνομα σας'),
    last_name: Yup.string().required('Εισάγετε το επώνυμο σας'),
    email: Yup.string()
      .email('Εισάγετε μια έγκυρη διεύθυνση email')
      .required('Εισάγετε το email σας'),
    phone_number: Yup.string()
      .required('Εισάγετε τον αριθμό σας')
      .test('phone', 'Εισάγετε έγκυρο αριθμό', (value) => {
        return isValidPhoneNumber(value);
      }),
    insurance_provider: Yup.object()
      .shape({ name: Yup.string(), id: Yup.number() })
      .required('Εισάγετε το ασφαλιστικό σας ταμείο'),
    ssrn: Yup.string()
      .required('Εισάγετε τον αριθμό μητρώου σας')
      .when('has_foreign_ssrn', {
        is: false,
        then: (schema) =>
          schema
            .matches(/^\d+$/, 'Εισάγετε μόνο αριθμούς')
            .max(11, 'Ο ΑΜΚΑ πρέπει να είναι 11 αριθμοί')
            .min(11, 'Ο ΑΜΚΑ πρέπει να είναι 11 αριθμοί')
            .test('ssrn', 'Μη έγκυρος ΑΜΚΑ', luhn_check),
      }),
    password: Yup.string()
      .required('Εισάγετε τον κωδικό σας')
      .min(8, 'Χρησιμοποιήστε τουλάχιστον 8 ψηφία'),
    tos_accepted: Yup.boolean().test(
      'tos_accepted',
      'Παρακαλώ επιβεβαίωστε την αποδοχή των όρων ιδιωτικής πολιτικής',
      function (value) {
        return value === true;
      },
    ),
    email_notifications_accepted: Yup.boolean().test(
      'email_notifications_accepted',
      'Παρακαλώ επιβεβαίωστε την αποδοχή ειδοποιήσεων μέσω Email',
      function (value) {
        return value === true;
      },
    ),
    sms_notifications_accepted: Yup.boolean().test(
      'sms_notifications_accepted',
      'Παρακαλώ επιβεβαίωστε την αποδοχή ειδοποιήσεων μέσω SMS',
      function (value) {
        return value === true;
      },
    ),
  });

  const handleVisible = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmit = async (
    values: FormFields,
    { setErrors, setSubmitting }: FormikHelpers<FormFields>,
  ) => {
    if (setSubmitting) {
      setSubmitting(true); // Set isSubmitting to true
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/internal/signup/`,
        {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          phone_number: values.phone_number,
          insurance_provider: values?.insurance_provider?.id || '',
          has_foreign_ssrn: values.has_foreign_ssrn,
          ssrn: values.ssrn,
          tos_accepted: values.tos_accepted,
          email_notifications_accepted: values.email_notifications_accepted,
          sms_notifications_accepted: values.sms_notifications_accepted,
        },
      );

      const { status, data } = response;

      if (status === 201 && data) {
        setUserId(data.id);
        setSignedUp(true);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        setErrors(error.response.data);
      } else {
        setErrorMsg('Ούπς! Κατι πηγε στραβά! Παρακαλώ προσπαθήστε αργοτερα!');
        console.log(error);
      }
    } finally {
      if (setSubmitting) {
        setSubmitting(false); // Set isSubmitting to false after submission
      }
    }
  };

  const handleValidation = async () => {
    try {
      await formRef?.current?.validateForm();
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendEmail = async () => {
    try {
      if (emailSent) return;
      const { status } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/send-verification-email/`,
        { id: userId },
      );

      if (status === 200) {
        setEmailSent((prev) => !prev);
        setEmailError('');
        resetSuccessfulMessage(setEmailSent);
      } else setEmailError('Υπήρξε κάποιο πρόβλημα με την αποστολή του email.');
    } catch (error) {
      console.log(error);
      setEmailError('Υπήρξε κάποιο πρόβλημα με την αποστολή του email.');
    }
  };

  useEffect(() => {
    !isFetching &&
      !isEmpty(insuranceProviders) &&
      isEmpty(availableInsuranceProviders) &&
      setAvailableInsuranceProviders(
        insuranceProviders as InsuranceProvidersType[],
      );
  });

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      {!signedUp ? (
        <div
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-duration="1000"
          className="mb-10 rounded-3xl bg-gray-50 px-4 py-10 text-center xl:px-14 2xl:px-20"
        >
          <h1 className="mb-10 text-2xl font-bold md:text-3xl lg:text-4xl ">
            Εγγραφείτε
          </h1>
          <Formik
            innerRef={formRef}
            initialValues={{
              first_name: '',
              last_name: '',
              phone_number: '',
              email: '',
              password: '',
              has_foreign_ssrn: false,
              ssrn: '',
              insurance_provider: null,
              tos_accepted: false,
              email_notifications_accepted: false,
              sms_notifications_accepted: false,
            }}
            validationSchema={RegisterSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="text-left">
                <div className="mb-6 grid gap-6 text-left md:grid-cols-2">
                  <InputField
                    labelName="first_name"
                    data-test="register-last_name-input"
                    label="ΟΝΟΜΑ"
                    name="first_name"
                    type="first_name"
                    id="first_name"
                    className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                  />
                  <InputField
                    labelName="last_name"
                    data-test="register-last_name-input"
                    label="ΕΠΩΝΥΜΟ"
                    name="last_name"
                    type="last_name"
                    id="last_name"
                    className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                  />
                  <div>
                    <label
                      htmlFor="phone_number"
                      className="textGray900 mb-1.5 block text-sm md:text-base"
                    >
                      ΤΗΛΕΦΩΝΟ ΕΠΙΚΟΙΝΩΝΙΑΣ
                    </label>
                    <CustomFormikPhoneInput name="phone_number" />
                    <ErrorMessage
                      name="phone_number"
                      component="div"
                      className="mt-2 text-xs text-red-600"
                      data-test="account-phone_number-error"
                    />
                  </div>
                  <InputField
                    labelName="email"
                    label="EMAIL ΕΠΙΚΟΙΝΩΝΙΑΣ - ΕΙΣΟΔΟΥ"
                    name="email"
                    type="email"
                    id="email"
                  />
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="mb-1.5 block text-sm text-gray-900 md:text-base"
                    >
                      ΚΩΔΙΚΟΣ
                    </label>
                    <Field
                      type={isVisible ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className=" block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                      data-test="register-password-input"
                    />
                    <div className="absolute inset-y-0 right-0 top-6 flex items-center pr-7 text-sm leading-5">
                      <button
                        onClick={handleVisible}
                        type="button"
                        className="h-8 w-8 text-primary hover:text-blue-900"
                      >
                        {!isVisible ? <VisibleIcon /> : <NotVisibleIcon />}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="mt-2 text-xs text-red-600"
                      data-test="register-password-error"
                    />
                  </div>
                  <div>
                    <div className="flex flex-row justify-between">
                      <label
                        htmlFor="ssrn"
                        className="textGray900 mb-1.5 block text-sm md:text-base"
                      >
                        ΑΜΚΑ
                      </label>
                      <div className="flex flex-row justify-between">
                        <div className="flex h-5 items-start lg:items-center">
                          <Field
                            data-test="register-has_foreign_ssrn"
                            name="has_foreign_ssrn"
                            type="checkbox"
                            className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                          />
                        </div>
                        <label
                          htmlFor="has_foreign_ssrn"
                          className="ml-2 text-sm text-gray-900"
                        >
                          Έχω αλλοδαπό ΑΜΚΑ
                        </label>
                      </div>
                    </div>
                    <Field
                      id="ssrn"
                      name="ssrn"
                      type="ssrn"
                      className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                    />
                    <ErrorMessage
                      name="ssrn"
                      component="div"
                      className="mt-2 text-xs text-red-600"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="insurance_provider"
                      className="mb-1.5 block text-sm text-gray-900 md:text-base"
                    >
                      ΑΣΦΑΛΕΙΑ
                    </label>
                    <div
                      className="relative mt-2 rounded-md shadow-sm"
                      aria-label="Select Service"
                    >
                      <Field
                        as={Select}
                        servicesIcon={simpleDoctor}
                        name="insurance_provider"
                        serviceGrp={availableInsuranceProviders || []}
                        data-test="register-insurance-input"
                        handleOnChange={(item: InsuranceProvidersType) => {
                          setFieldValue('insurance_provider', item);
                        }}
                      />
                      <ErrorMessage
                        name="insurance_provider"
                        component="div"
                        className=" mt-2 text-xs text-red-600"
                        data-test="register-insurance_provider-error"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-6 flex flex-col items-start">
                  <div className="flex items-center">
                    <Field
                      data-test="register-tos_accepted"
                      name="tos_accepted"
                      type="checkbox"
                      className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                    />
                    <label
                      htmlFor="processing"
                      className="ml-2 text-base text-gray-900 md:text-xl"
                    >
                      Συναινώ για την επεξεργασία των στοιχείων μου σύμφωνα με
                      την πολιτική απορρήτου και προστασίας προσωπικών
                      δεδομένων.
                    </label>
                  </div>
                  <ErrorMessage
                    name="tos_accepted"
                    component="div"
                    className="ml-6 mt-2 text-xs text-red-600"
                    data-test="register-terms-error"
                  />
                </div>
                <div className="mb-6 flex flex-col items-start">
                  <div className="flex items-center">
                    <Field
                      data-test="register-email_notifications_accepted"
                      name="email_notifications_accepted"
                      type="checkbox"
                      className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                    />
                    <label
                      htmlFor="contact"
                      className="ml-2 text-base text-gray-900 md:text-xl"
                    >
                      Συναινώ στην αποστολή ενημερώσεων αποκλειστικά για τους
                      σκοπούς ενημερώσεων για το ραντεβού μου, στην διεύθυνση
                      email μου.
                    </label>
                  </div>
                  <ErrorMessage
                    name="email_notifications_accepted"
                    component="div"
                    className="ml-6 mt-2 text-xs text-red-600"
                    data-test="register-email-notifications-error"
                  />
                </div>
                <div className="mb-6 flex flex-col items-start">
                  <div className="flex items-center">
                    <Field
                      data-test="register-sms_notifications_accepted"
                      name="sms_notifications_accepted"
                      type="checkbox"
                      className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                    />
                    <label
                      htmlFor="contact-sms"
                      className="ml-2 text-base text-gray-900 md:text-xl"
                    >
                      Συναινώ στην αποστολή ενημερώσεων μέσω SMS αποκλειστικά
                      για τους σκοπούς ενημερώσεων για το ραντεβού μου.
                    </label>
                  </div>
                  <ErrorMessage
                    name="sms_notifications_accepted"
                    component="div"
                    className="ml-6 mt-2 text-xs text-red-600"
                    data-test="register-sms-notifications-error"
                  />
                </div>
                <div className="mb-6 flex w-full flex-row flex-wrap items-center justify-center text-lg md:text-xl">
                  <p>Έχετε λογαριασμό; Συνδεθείτε</p> &nbsp;{' '}
                  <a
                    href="/login/"
                    className="font-bold text-primary hover:text-gray-600"
                  >
                    εδώ
                  </a>
                </div>
                <div className="w-full text-center">
                  <button
                    onClick={handleValidation}
                    type="submit"
                    data-test="register-submit-button"
                    className="inline-flex items-center rounded-full border border-primary bg-primary px-6  py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                  >
                    {isSubmitting ? 'Επεξεργασία...' : 'Εγγραφή'}

                    <NextStepIcon className="ml-2.5 h-6 w-6" />
                  </button>
                  {errorMsg && (
                    <p
                      data-test="error-field"
                      className="mx-auto w-full max-w-screen-2xl px-4 py-7 text-center text-red-500 md:py-8 lg:px-10"
                    >
                      {errorMsg}
                    </p>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center opacity-100">
          <h1 className="p-5 text-center text-4xl font-bold text-green-700">
            Σας έχει αποσταλεί email επιβεβαίωσης εγγραφής!
          </h1>
          {emailSent ? (
            <div className="pb-16 pt-10 md:pb-12 md:pt-7">
              <SuccesfulEmailResentMessage />
            </div>
          ) : (
            <p className="max-w-xl pb-10 pt-5 text-center text-xl">
              Επιβεβαιώστε την εγγραφή σας ακολουθώντας τον σύνδεσμο στο email
              που σας έχουμε αποστείλει και στη συνέχεια συνδεθείτε!
            </p>
          )}
          {emailError && (
            <div className="pb-10">
              <Error message={emailError} />
            </div>
          )}
          <div className="w-full text-center">
            <button
              onClick={handleResendEmail}
              type="button"
              className="inline-flex items-center rounded-full border border-primary bg-primary px-6  py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
            >
              Επανάληψη αποστολής
              <NextStepIcon className="ml-2.5 h-6 w-6" />
            </button>
          </div>
        </div>
      )}
    </main>
  );
}
