import { ExclamationMark } from 'components/svgs';

interface Props {
  message: string;
}

export default function ErrorMessage({ message }: Props) {
  return (
    <div className="bg-red-0 mx-auto flex max-w-xl flex-col items-center rounded-lg bg-red-100 p-5 text-center">
      <div className="mb-4 h-14 w-14 text-red-700">
        <ExclamationMark />
      </div>
      <p className="max-w-4xl text-lg font-bold text-red-950 md:text-xl">
        {message}
      </p>
    </div>
  );
}
