import { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useInsuranceProviders } from 'api';
import { Select } from 'components/Select';
import simpleDoctor from 'img/simple-doctor.svg';
import useStore from 'store';
import request from 'request';
import { useAuth } from 'auth/AuthProvider';
import { VisitorInfoFormType, InsuranceProvidersType } from 'types';
import useStoreServices from 'storeServices';
import { isEmpty } from 'lodash';
import { luhn_check } from '../../../../helpers';
import { CustomFormikPhoneInput } from 'components/InternationalPhoneNumber';
import { isValidPhoneNumber } from 'react-phone-number-input';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Εισάγετε το όνομα σας'),
  last_name: Yup.string().required('Εισάγετε το επώνυμο σας'),
  insurance_provider: Yup.object()
    .shape({ name: Yup.string(), id: Yup.number() })
    .required('Εισάγετε το ασφαλιστικό σας ταμείο'),
  ssrn: Yup.string().when('has_foreign_ssrn', {
    is: false,
    then: (schema) =>
      schema
        .matches(/^\d+$/, 'Εισάγετε μόνο αριθμούς')
        .max(11, 'Ο ΑΜΚΑ πρέπει να είναι 11 αριθμοί')
        .min(11, 'Ο ΑΜΚΑ πρέπει να είναι 11 αριθμοί')
        .test('ssrn', 'Μη έγκυρος ΑΜΚΑ', (value) => {
          if (!value) {
            return true;
          }
          return luhn_check(value);
        }),
  }),
  email: Yup.string()
    .email('Εισάγετε μια έγκυρη διεύθυνση email')
    .required('Εισάγετε το email σας'),
  phone_number: Yup.string()
    .required('Εισάγετε τον αριθμό σας')
    .test('phone', 'Εισάγετε έγκυρο αριθμό', (value) => {
      return isValidPhoneNumber(value);
    }),
  escort: Yup.boolean(),
  escort_first_name: Yup.string().when('escort', {
    is: true,
    then: (schema) => schema.required('Εισάγετε το όνομα σας'),
  }),
  escort_last_name: Yup.string().when('escort', {
    is: true,
    then: (schema) => schema.required('Εισάγετε το επώνυμο σας'),
  }),
  escort_phone: Yup.string().when('escort', {
    is: true,
    then: (schema) =>
      schema
        .required('Εισάγετε τον αριθμό σας')
        .test('phone', 'Εισάγετε έγκυρο αριθμό', (value) => {
          return isValidPhoneNumber(value);
        }),
  }),
  tos_accepted: Yup.boolean()
    .oneOf(
      [true],
      'Παρακαλώ επιβεβαίωστε την αποδοχή των όρων ιδιωτικής πολιτικής',
    )
    .required('Παρακαλώ επιβεβαίωστε την αποδοχή των όρων ιδιωτικής πολιτικής'),
  email_notifications_accepted: Yup.boolean()
    .oneOf([true], 'Παρακαλώ επιβεβαίωστε την αποδοχή ειδοποιήσεων μέσω Email')
    .required('Παρακαλώ επιβεβαίωστε την αποδοχή ειδοποιήσεων μέσω Email'),
  sms_notifications_accepted: Yup.boolean()
    .oneOf([true], 'Παρακαλώ επιβεβαίωστε την αποδοχή ειδοποιήσεων μέσω SMS')
    .required('Παρακαλώ επιβεβαίωστε την αποδοχή ειδοποιήσεων μέσω SMS'),
});

interface Props {
  onSubmitVisitorInfo: (values: VisitorInfoFormType) => void;
  formikRef: React.RefObject<FormikProps<VisitorInfoFormType>>;
}

export default function VisitorInfo({ onSubmitVisitorInfo, formikRef }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const { visitorInfo, setVisitorInfo } = useStore();
  const { availableInsuranceProviders, setAvailableInsuranceProviders } =
    useStoreServices();
  const { data: insuranceProviders, isFetching } = useInsuranceProviders();
  const { token } = useAuth();

  // @TODO Fix the type of data
  const transformUserData = (data: VisitorInfoFormType) => {
    const provider = availableInsuranceProviders.find(
      (insurance) =>
        insurance.id ===
        (data?.insurance_provider?.id ?? data?.insurance_provider),
    );

    return {
      ...data,
      insurance_provider: provider,
    };
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await request.get('/api/v1/auth/me/');
        const transformedData = transformUserData(data);
        setVisitorInfo(transformedData as VisitorInfoFormType);
      } catch (error) {
        console.error('Error fetching user data:', (error as Error).message);
      }
    };

    if (token) {
      fetchUserData();
    }
  }, [token, setVisitorInfo, availableInsuranceProviders]); // eslint-disable-line

  useEffect(() => {
    !isFetching &&
      !isEmpty(insuranceProviders) &&
      isEmpty(availableInsuranceProviders) &&
      setAvailableInsuranceProviders(
        insuranceProviders as InsuranceProvidersType[],
      );
  });

  const initialValues = {
    first_name: visitorInfo?.first_name ?? '',
    last_name: visitorInfo?.last_name ?? '',
    insurance_provider:
      availableInsuranceProviders.find(
        (insurance) => insurance.id === visitorInfo?.insurance_provider?.id,
      ) ?? null,
    has_foreign_ssrn: visitorInfo?.has_foreign_ssrn ?? false,
    ssrn: visitorInfo?.ssrn ?? '',
    phone_number: visitorInfo?.phone_number ?? '',
    email: visitorInfo?.email ?? '',
    notes: visitorInfo?.notes ?? '',
    escort: false,
    escort_first_name: '',
    escort_last_name: '',
    escort_phone: '',
    tos_accepted: false,
    email_notifications_accepted: false,
    sms_notifications_accepted: false,
  };

  return (
    <>
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mb-10 rounded-3xl px-4 py-10 pb-0 text-center xl:px-14 2xl:px-20"
      >
        <h1 className="mx-auto mb-4 max-w-xs text-center text-xl font-bold sm:max-w-md sm:text-2xl md:max-w-lg md:text-3xl lg:max-w-xl">
          Συμπληρώστε λεπτομέρειες για την επιβεβαίωση της κράτησης
        </h1>
        <p className="mx-auto mb-6 text-center sm:mb-10">
          Προγραμματίζετε ένα ραντεβού
        </p>
      </div>

      <section
        data-aos="fade-in"
        data-aos-once="true"
        data-aos-duration="1500"
        className="mb-10 rounded-3xl bg-gray-50 px-4 py-10 text-center lg:text-left xl:px-14 2xl:px-20"
      >
        {!isFetching && (
          <div className="mx-auto mb-6 max-w-6xl">
            <h1 className="mb-6 text-lg font-bold print:hidden md:text-xl lg:text-2xl">
              Στοιχεία Επισκέπτη
            </h1>

            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitVisitorInfo}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => {
                return (
                  <Form className="text-left">
                    <div className="mx-auto mb-6 grid max-w-lg gap-6 text-left lg:max-w-full lg:grid-cols-2">
                      <div>
                        <label
                          htmlFor="first_name"
                          className="mb-1.5 block text-sm text-gray-900 md:text-base"
                        >
                          ΟΝΟΜΑ
                        </label>
                        <Field
                          type="text"
                          id="first_name"
                          name="first_name"
                          className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                        />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="mt-2 text-xs text-red-600"
                          data-test="contact-first_name-error"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="first_name"
                          className="mb-1.5 block text-sm text-gray-900 md:text-base"
                        >
                          ΕΠΩΝΥΜΟ
                        </label>
                        <Field
                          type="text"
                          id="last_name"
                          name="last_name"
                          className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="mt-2 text-xs text-red-600"
                          data-test="contact-last_name-error"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="insurance_provider"
                          className="mb-1.5 block text-sm text-gray-900 md:text-base"
                        >
                          ΑΣΦΑΛΕΙΑ
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <Field
                            as={Select}
                            name="insurance_provider"
                            id="insurance_provider"
                            serviceGrp={availableInsuranceProviders || []}
                            servicesIcon={simpleDoctor}
                            handleOnChange={(item: InsuranceProvidersType) => {
                              console.log(item);
                              setFieldValue('insurance_provider', item);
                            }}
                            data-test="register-insurance-field"
                            className={'user-area-input'}
                          />
                        </div>
                        <ErrorMessage
                          name="insurance_provider"
                          component="div"
                          className="mt-2 text-xs text-red-600"
                          data-test="contact-insurance-error"
                        />
                      </div>
                      <div>
                        <div className="flex flex-row justify-between">
                          <label
                            htmlFor="ssrn"
                            className="mb-1.5 block text-sm text-gray-900 md:text-base"
                          >
                            ΑΜΚΑ
                          </label>
                          <div className="flex flex-row justify-between">
                            <div className="flex h-5 items-start lg:items-center">
                              <Field
                                data-test="visitor-has_foreign_ssrn"
                                name="has_foreign_ssrn"
                                type="checkbox"
                                className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                              />
                            </div>
                            <label
                              htmlFor="has_foreign_ssrn"
                              className="ml-2 text-sm text-gray-900"
                            >
                              Έχω αλλοδαπό ΑΜΚΑ
                            </label>
                          </div>
                        </div>
                        <Field
                          type="string"
                          id="ssrn"
                          name="ssrn"
                          className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                        />
                        <ErrorMessage
                          name="ssrn"
                          component="div"
                          className="mt-2 text-xs text-red-600"
                          data-test="contact-ssrn-error"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="phone_number"
                          className="mb-1.5 block text-sm text-gray-900 md:text-base"
                        >
                          ΤΗΛΕΦΩΝΟ ΕΠΙΚΟΙΝΩΝΙΑΣ
                        </label>
                        <CustomFormikPhoneInput name="phone_number" />
                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="mt-2 text-xs text-red-600"
                          data-test="contact-phone_number-error"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="mb-1.5 block text-sm text-gray-900 md:text-base"
                        >
                          EMAIL ΕΠΙΚΟΙΝΩΝΙΑΣ
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="mt-2 text-xs text-red-600"
                          data-test="contact-email-error"
                        />
                      </div>
                    </div>
                    <div className="mx-auto mb-6 max-w-lg text-left lg:max-w-full">
                      <label
                        htmlFor="notes"
                        className="mb-1.5 block text-sm text-gray-900 md:text-base"
                      >
                        ΛΕΠΤΟΜΕΡΕΙΕΣ ΘΕΜΑΤΟΣ (ΠPOAIΡΕΤΙΚΟ)
                      </label>
                      <Field
                        as="textarea"
                        type="textarea"
                        id="notes"
                        name="notes"
                        rows={6}
                        className="block w-full resize-y rounded-xl border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 md:text-xl"
                      ></Field>
                    </div>
                    <div className="mb-6 flex items-start justify-center sm:me-5 sm:items-center lg:me-0 lg:justify-start">
                      <div className="flex h-5 items-center">
                        <Field
                          name="escort"
                          id="escort"
                          type="checkbox"
                          onClick={() => {
                            setIsVisible(!isVisible);
                          }}
                          className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                        />
                      </div>
                      <label
                        htmlFor="other"
                        className="ml-2 text-base text-gray-900 md:text-xl"
                      >
                        Το ραντεβού αφορά τρίτο πρόσωπο / Είμαι Συνοδός.
                      </label>
                    </div>
                    <div
                      className={`mx-auto max-w-lg text-left lg:max-w-full ${
                        !isVisible && `pointer-events-none opacity-30`
                      }`}
                    >
                      <h2 className="mb-6 text-center text-lg font-bold print:hidden md:text-xl lg:text-start lg:text-2xl">
                        Στοιχεία Συνοδού
                      </h2>

                      <div className="mb-6 grid gap-6 lg:grid-cols-2">
                        <div>
                          <label
                            htmlFor="other_first_name"
                            className="mb-1.5 block text-sm text-gray-900 md:text-base"
                          >
                            ΟΝΟΜΑ
                          </label>
                          <Field
                            disabled={!isVisible}
                            type="text"
                            id="escort_first_name"
                            name="escort_first_name"
                            className="dis block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                          />
                          <ErrorMessage
                            name="escort_first_name"
                            component="div"
                            className="mt-2 text-xs text-red-600"
                            data-test="contact-escort_first_name-error"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="other_last_name"
                            className="mb-1.5 block text-sm text-gray-900 md:text-base"
                          >
                            ΕΠΩΝΥΜΟ
                          </label>
                          <Field
                            disabled={!isVisible}
                            type="text"
                            id="escort_last_name"
                            name="escort_last_name"
                            className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                          />
                          <ErrorMessage
                            name="escort_last_name"
                            component="div"
                            className="mt-2 text-xs text-red-600"
                            data-test="contact-escort_last_name-error"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="other_phone"
                            className="mb-1.5 block text-sm text-gray-900 md:text-base"
                          >
                            ΤΗΛΕΦΩΝΟ ΕΠΙΚΟΙΝΩΝΙΑΣ
                          </label>
                          <CustomFormikPhoneInput name="escort_phone" />
                          <ErrorMessage
                            name="escort_phone"
                            component="div"
                            className="mt-2 text-xs text-red-600"
                            data-test="contact-escort_phone-error"
                          />
                        </div>
                      </div>
                    </div>
                    {/* {third person information} */}

                    <div className="mx-auto mb-6 flex max-w-lg flex-col items-start lg:max-w-full">
                      <div className="flex items-start lg:items-center">
                        <Field
                          data-test="visitor-tos_accepted"
                          name="tos_accepted"
                          type="checkbox"
                          className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                        />
                        <label
                          htmlFor="tos_accepted"
                          className="ml-2 text-base text-gray-900 md:text-xl"
                        >
                          Συναινώ για την επεξεργασία των στοιχείων μου σύμφωνα
                          με την πολιτική απορρήτου και προστασίας προσωπικών
                          δεδομένων.
                        </label>
                      </div>
                      <ErrorMessage
                        name="tos_accepted"
                        component="div"
                        className="ml-6 mt-2 text-xs text-red-600"
                        data-test="contact-terms-error"
                      />
                    </div>
                    <div className="mx-auto mb-6 flex max-w-lg flex-col items-start lg:max-w-full">
                      <div className="flex items-start lg:items-center">
                        <Field
                          data-test="visitor-email_notifications_accepted"
                          name="email_notifications_accepted"
                          type="checkbox"
                          className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                        />
                        <label
                          htmlFor="email_notifications_accepted"
                          className="ml-2 text-base text-gray-900 md:text-xl"
                        >
                          Συναινώ στην αποστολή ενημερώσεων αποκλειστικά για
                          τους σκοπούς ενημερώσεων για το ραντεβού μου, στην
                          διεύθυνση email μου.
                        </label>
                      </div>
                      <ErrorMessage
                        name="email_notifications_accepted"
                        component="div"
                        className="ml-6 mt-2 text-xs text-red-600"
                        data-test="contact-email_notifications_accepted-error"
                      />
                    </div>
                    <div className="mx-auto mb-6 flex max-w-lg flex-col items-start lg:max-w-full">
                      <div className="flex items-start lg:items-center">
                        <Field
                          data-test="visitor-sms_notifications_accepted"
                          name="sms_notifications_accepted"
                          type="checkbox"
                          className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                        />
                        <label
                          htmlFor="sms_notifications_accepted"
                          className="ml-2 text-base text-gray-900 md:text-xl"
                        >
                          Συναινώ στην αποστολή ενημερώσεων μέσω SMS
                          αποκλειστικά για τους σκοπούς ενημερώσεων για το
                          ραντεβού μου.
                        </label>
                      </div>
                      <ErrorMessage
                        name="sms_notifications_accepted"
                        component="div"
                        className="ml-6 mt-2 text-xs text-red-600"
                        data-test="contact-sms-error"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </section>

      <section data-aos="fade-in" data-aos-once="true" data-aos-duration="1500">
        <h2 className="mb-2 text-center text-2xl font-bold">Κράτηση</h2>
        <p className="mx-auto mb-6 max-w-lg text-center">
          Δείτε τα ραντεβού που έχετε προγραμματίσει παρακάτω.
          <br />Η δέσμευση τους για τα επόμενα 15 λεπτά έχει ξεκινήσει.
        </p>
      </section>
    </>
  );
}
