import { Outlet } from 'react-router-dom';
import Header from 'components/Header/Header';
import Footer from 'components/Footer';

function App() {
  return (
    <div className="bg-slate-200 flex min-h-screen w-full flex-col justify-between">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
