import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FormikProps } from 'formik';

import useStore from 'store';
import VisitorInfoForm from './VisitorInfoForm';
import Appointment from '../../components/Appointment';
import NavigationButtons from '../../components/NavigationButtons';
import { VisitorInfoFormType } from 'types';
import useStoreServices from 'storeServices';

export default function UserInput() {
  const {
    appointments,
    setRemovalModalState,
    setAddAttachmentModalState,
    removalModalState,
    addAttachmentModalState,
    setVisitorInfo,
    setCurrentAppointment,
  } = useStore();

  const { availableArrivalPolicies } = useStoreServices();

  const navigate = useNavigate();
  const formRef = useRef<FormikProps<VisitorInfoFormType>>(null!);
  const { isOpen } = removalModalState || addAttachmentModalState;

  // redirect to home page if no appointments in state and modal was closed
  useEffect(() => {
    if (isEmpty(appointments) && !isOpen) {
      navigate('/');
    }
  }, [appointments, navigate, isOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <VisitorInfoForm
        formikRef={formRef}
        onSubmitVisitorInfo={(values) => {
          console.log('Values', values);
          setVisitorInfo(values);
        }}
      />

      {appointments?.map((appointment) => (
        <Appointment
          key={appointment?.id ?? crypto.randomUUID()}
          appointment={appointment}
          policies={availableArrivalPolicies}
          onDelete={() => {
            setRemovalModalState({ isOpen: true, appointment });
          }}
          onAddAttachment={() => {
            setAddAttachmentModalState({ isOpen: true, appointment });
          }}
          onEdit={() => {
            setCurrentAppointment(appointment);
            navigate('/new/reservations');
          }}
        />
      ))}

      <NavigationButtons
        isForwardButtonDisabled={appointments.length === 0}
        isPageUserReservationPreview={false}
        onPreviousStep={() => {
          navigate(-1);
        }}
        onForwardStep={async () => {
          try {
            const errors = await formRef?.current?.validateForm();
            formRef?.current?.handleSubmit();
            if (errors) {
              window.scrollTo({
                top: 250,
                behavior: 'smooth',
              });
            }

            if (isEmpty(errors)) {
              navigate('/new/reservations-preview');
            }
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </>
  );
}
