import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { omit } from 'lodash';

import {
  AppointmentAttachment,
  AppointmentType,
  VisitorInfoFormType,
  VisitorsIDs,
} from 'types';

export interface StateType {
  // currentAppointment
  currentAppointment?: AppointmentType;
  setCurrentAppointment: (currentAppointment?: AppointmentType) => void;
  // appointments
  appointments: AppointmentType[];
  setAppointment: (appointment: AppointmentType) => void;
  updateAppointments: (appointments: Array<AppointmentType>) => void;
  clearAppointments: () => void;
  setCurrentAppointmentFiles: (
    appoinntment: AppointmentType,
    file: AppointmentAttachment,
  ) => void;
  deleteAppointment: (id: string) => void;
  editAppointment: (appoinment: AppointmentType) => void;
  // visitor info form data
  visitorInfo?: VisitorInfoFormType;
  setVisitorInfo: (formValues: VisitorInfoFormType) => void;
  visitorIDs?: VisitorsIDs;
  setVisitorIDs: (ids: VisitorsIDs) => void;
  // instuctions modal
  isInstructionsModalOpen: boolean;
  setIsInstuctionsModalOpen: (value: boolean) => void;
  instructionModalText: string;
  setInstructionModalText: (value: string) => void;
  // removal modal state: open and appointment for deletion
  removalModalState: { isOpen: boolean; appointment?: AppointmentType };
  setRemovalModalState: ({
    isOpen,
    appointment,
  }: {
    isOpen: boolean;
    appointment?: AppointmentType;
  }) => void;
  // add attachment modal state: open and appointment to attach files
  addAttachmentModalState: { isOpen: boolean; appointment?: AppointmentType };
  setAddAttachmentModalState: ({
    isOpen,
    appointment,
  }: {
    isOpen: boolean;
    appointment?: AppointmentType;
  }) => void;
  // apps expired
  isAppsExpired: boolean;
  setIsAppsExpired: (value: boolean) => void;
  actions: {
    setIsTest: (value: boolean) => void;
  };
  // appointment daterange
  isDatetimeRangeOk: boolean;
  setIsDatetimeRangeOk: (value: boolean) => void;
}

const useStore = create<StateType>()(
  devtools((set, get) => ({
    // currentAppointment
    currentAppointment: undefined,
    // deleteTuna: () => set((state) => omit(state, ['tuna']), true),
    setCurrentAppointment: (
      currentAppointment: AppointmentType | undefined,
    ) => {
      if (currentAppointment === undefined) {
        set((state) => omit(state, ['currentAppointment']), true);
      } else {
        set((state) => ({
          currentAppointment: {
            ...state.currentAppointment,
            ...currentAppointment,
          },
        }));
      }
    },
    // appointments
    appointments: [],
    setAppointment: (appointment: AppointmentType) => {
      set((state) => {
        state.setIsDatetimeRangeOk(
          !state.appointments.some(
            (a) => a.start! < appointment.end! && appointment.start! < a.end!,
          ),
        );
        return {
          appointments: [...state.appointments, appointment],
        };
      });
    },
    updateAppointments: (appoinments: AppointmentType[]) => {
      set((state) => {
        state.appointments.forEach((app) => {
          const fetched = appoinments.find((updated) => updated.id === app.id);
          app.status = fetched?.status;
          app.primary_contact = fetched?.primary_contact;
          app.secondary_contact = fetched?.secondary_contact;
        });
        return { appointments: [...state.appointments] };
      });
    },
    clearAppointments: () => {
      set(() => ({ appointments: [] }));
    },
    setCurrentAppointmentFiles: (
      appointment: AppointmentType,
      file: AppointmentAttachment,
    ) => {
      set((state) => {
        const target = state.appointments.find(
          (app) => app.id === appointment.id,
        );
        target?.attachments?.push(file);
        return { appointments: state.appointments };
      });
    },
    deleteAppointment: (id) => {
      set((state) => {
        let appointment = state.appointments.find(
          (a: AppointmentType) => a.id === id,
        );
        state.setIsDatetimeRangeOk(
          !state.appointments.some(
            (a: AppointmentType) =>
              a.start! < appointment?.end! && appointment?.start! < a.end!,
          ),
        );
        return {
          appointments: state.appointments.filter(
            (appointment: AppointmentType) => appointment.id !== id,
          ),
        };
      });
    },
    editAppointment: (appointment) => {
      set((state) => {
        state.setIsDatetimeRangeOk(
          !state.appointments.some(
            (a: AppointmentType) =>
              a.start! < appointment.end! && appointment.start! < a.end!,
          ),
        );
        return {
          appointments: state.appointments.map((app) =>
            app.id === appointment.id ? { ...app, ...appointment } : app,
          ),
        };
      });
    },
    // visitor info form data
    visitorInfo: {
      first_name: '',
      last_name: '',
      insurance_provider: null,
      has_foreign_ssrn: false,
      ssrn: '',
      phone_number: '',
      email: '',
      notes: '',
      escort: false,
      escort_first_name: '',
      escort_last_name: '',
      escort_phone: '',
      tos_accepted: false,
      email_notifications_accepted: false,
      sms_notifications_accepted: false,
    },
    setVisitorInfo: (formValues) => set(() => ({ visitorInfo: formValues })),
    visitorIDs: { primaryContact: '', secondaryContact: '' },
    setVisitorIDs: (ids) => set(() => ({ visitorIDs: ids })),
    // instuctions modal
    isInstructionsModalOpen: false,
    setIsInstuctionsModalOpen: (value) =>
      set(() => ({ isInstructionsModalOpen: value })),
    instructionModalText: '',
    setInstructionModalText: (value) =>
      set(() => ({ instructionModalText: value })),
    // removal modal state: open and appointment for deletion
    removalModalState: { isOpen: false, appointment: undefined },
    setRemovalModalState: ({ isOpen, appointment }) =>
      set(() => ({ removalModalState: { isOpen, appointment } })),
    // add attachment modal state: open and appointment for attaching files
    addAttachmentModalState: { isOpen: false, appointment: undefined },
    setAddAttachmentModalState: ({ isOpen, appointment }) =>
      set(() => ({ addAttachmentModalState: { isOpen, appointment } })),
    // apps expired
    isAppsExpired: false,
    setIsAppsExpired: (value) => set(() => ({ isAppsExpired: value })),
    actions: {
      setIsTest: (value) => set(() => ({ isAppsExpired: value })),
    },
    // appointment daterange
    isDatetimeRangeOk: true,
    setIsDatetimeRangeOk: (value) => set(() => ({ isDatetimeRangeOk: value })),
  })),
);

export default useStore;
