import React, { SVGProps } from 'react';

const SimpleDoctorIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#287AC3"
        fillRule="evenodd"
        d="M3.494 2.736C4 1.35 5.154.339 6.561.048 6.964-.037 7.777 0 8.214.123c.874.246 1.683.834 2.241 1.626.248.358.554 1.043.668 1.508.09.353.096.45.096 1.094 0 .588-.013.752-.075.998-.42 1.645-1.453 2.769-2.94 3.208-.269.082-.39.093-.975.093-.62 0-.695-.008-1.026-.108a3.995 3.995 0 0 1-1.848-1.184c-.503-.554-.83-1.187-1.037-1.998-.065-.257-.079-.42-.079-.99-.003-.745.045-1.069.255-1.634Zm1.56 6.834a8.11 8.11 0 0 1 1.129-.213c.293-.033 1.886-.022 2.186.012.427.052 1.057.193 1.098.249.09.119.358 1.705.39 2.289l.02.413-.32.175a6.369 6.369 0 0 0-1.587 1.228c-.551.596-.802 1.065-.889 1.664a1.929 1.929 0 0 0 .448 1.511c.368.436.658.547.857.331.22-.238.18-.431-.148-.744-.458-.436-.523-.878-.22-1.507.258-.54 1.084-1.314 1.87-1.746l.24-.13.368.417c1.16 1.31 1.522 2.527.93 3.133a1.706 1.706 0 0 1-.372.254c-.213.115-.279.17-.33.29-.055.123-.059.167-.024.287.048.152.216.286.365.286.182.004.678-.298.915-.554.341-.373.49-.764.49-1.3 0-.926-.51-1.972-1.485-3.04l-.347-.384-.021-.554a10.702 10.702 0 0 0-.227-1.738c-.031-.142-.048-.261-.038-.272.027-.03.72.365 1.053.603 1.384.986 2.386 2.475 2.803 4.172.21.864.223 1.02.24 3.007.021 2.025.014 2.085-.182 2.211-.113.078-.237.078-7.03.078-6.207 0-6.926-.007-7.023-.059C.007 19.824 0 19.764 0 18.056c0-2.181.055-2.732.365-3.745.513-1.675 1.611-3.126 3.04-4.008.175-.112.327-.194.337-.186.007.007-.083.323-.2.7-.32 1.023-.681 2.635-.781 3.468-.02.179-.034.209-.117.227-.368.093-.799.506-.992.942-.11.25-.117.294-.117.696 0 .402.007.447.117.692.062.145.193.354.286.462.771.89 2.104.651 2.592-.462.107-.245.114-.29.114-.692 0-.402-.007-.447-.114-.696-.148-.339-.43-.659-.709-.808a1.126 1.126 0 0 0-.268-.119c-.066 0-.059-.044.134-1.094a20.26 20.26 0 0 1 .943-3.402l.148-.383.276-.078Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SimpleDoctorIcon;
