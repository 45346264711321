import { useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { CloseIcon, TickIcon, ExclamationMark } from 'components/svgs';
import { AppointmentType, UploadAppointmentAttachment } from 'types';
import { useAddAppointmentAttachment } from 'api';
import { Loading } from 'components/Loader';
import useStore from 'store';
import { isEmpty } from 'lodash';
import request from 'request';

interface Props {
  onClose: () => void;
  appointment: AppointmentType;
  open: boolean;
}

const AddAttachmentModal = ({ open = false, onClose, appointment }: Props) => {
  const [isOpen, setIsOpen] = useState(open);
  const [fileName, setFileName] = useState('');
  const fileRef = useRef<HTMLInputElement>(null);
  const [isSuccessfullUpload, setIsSuccessfullUpload] = useState<
    boolean | undefined
  >(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: addAppointmentAttachment } =
    useAddAppointmentAttachment();

  const [currentAttachment, setCurrentAttachment] = useState<FormData>();

  const { setCurrentAppointmentFiles, appointments } = useStore();

  const AddFiles = (file: File) => {
    let attachment = new FormData();
    attachment?.append('file', file);
    attachment?.append('description', `${file.name}`);
    setCurrentAttachment(attachment);
    setFileName(file?.name);
  };

  const refetchAttachments = async () => {
    try {
      setIsSuccessfullUpload(true);
      const { data, status } = await request.get(
        `/api/v1/reservations/${appointment.id}/attachments/`,
      );
      status === 200 && (appointment.attachments = [...data]);
      console.log(data);
    } catch (error) {
      console.log(error);
      setIsSuccessfullUpload(false);
    }
  };

  const handleUploadAttachments = async (
    attachment: UploadAppointmentAttachment,
  ): Promise<void> => {
    console.log(attachment);
    try {
      const response = await addAppointmentAttachment(attachment);
      setCurrentAppointmentFiles(attachment.appointment, response);
      setIsSuccessfullUpload(true);
      setCurrentAttachment(undefined);
      setFileName('');
      isEmpty(appointments) && (await refetchAttachments());
      console.log(appointments);
    } catch (error: any) {
      setIsSuccessfullUpload(false);
      setFileName('');
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleFileUpload = (): void => {
    fileRef.current?.click();
  };

  const handleClose = (): void => {
    setIsOpen(false);
    setFileName('');
    setCurrentAttachment(undefined);
    onClose?.();
  };

  useEffect(() => {
    // cleanup state when modal closes
    return () => {
      setIsSuccessfullUpload(undefined);
    };
  }, [open]);

  return (
    <Transition appear show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex flex-col items-center justify-center p-4 print:absolute print:h-auto"
        onClose={handleClose}
        open={isOpen}
      >
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="relative max-h-full w-full max-w-lg"
        >
          <Dialog.Panel className="relative flex max-h-full flex-col rounded-lg bg-white shadow print:block print:h-auto print:rounded-none print:shadow-none">
            <div className="flex items-center justify-between rounded-t border-b bg-gray-100 p-5">
              <Dialog.Title
                as="h3"
                className="flex-1 pl-4 text-center text-xl font-medium text-gray-900"
              >
                Προσθήκη αρχείων στο ραντεβού
              </Dialog.Title>
              <button
                onClick={handleClose}
                type="button"
                className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 transition-all hover:bg-blue-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-200 print:hidden"
              >
                <CloseIcon className="h-5 w-5" />
              </button>
            </div>

            {isLoading && (
              <>
                <div className="mt-6 flex w-full flex-row justify-end pe-10">
                  <Loading size={60} />
                </div>
              </>
            )}

            <div className="mx-auto max-w-sm space-y-4 p-6">
              {!isEmpty(appointment?.attachments) && (
                <div className="max-w-sm text-center">
                  <span className="text-lg font-bold">
                    Αρχεία που έχουν προστεθεί:
                  </span>
                  <ul>
                    {appointment.attachments?.map((attachment) => (
                      <li key={attachment.id}>{attachment.description}</li>
                    ))}
                  </ul>
                </div>
              )}
              {!isLoading && (
                <>
                  <div className="mx-auto text-center">
                    <button
                      onClick={handleFileUpload}
                      className="inline-flex items-center rounded-full border border-green-700 bg-green-600 px-4 py-2 text-center text-base font-medium text-white transition-all hover:bg-green-100/50 hover:text-green-600 focus:outline-none focus:ring-4 focus:ring-green-300"
                    >
                      Προσθήκη Αρχείου
                    </button>
                    {fileName && (
                      <p className="py-4">
                        <span className="font-bold">Επιλεγμένο αρχείο: </span>
                        {fileName}
                      </p>
                    )}
                    <input
                      ref={fileRef}
                      className="hidden"
                      type="file"
                      name="file"
                      onChange={(event) => {
                        console.log(event.target.files);
                        AddFiles(event.target.files![0]);
                      }}
                    />
                  </div>
                </>
              )}
              {!isLoading && isSuccessfullUpload && (
                <>
                  <div className="inline-flex items-start justify-center">
                    <div className="mx-auto me-3 h-5 w-5 text-green-700">
                      <TickIcon />
                    </div>
                    <p className="max-w-sm text-center">
                      Το αρχείο σας προστέθηκε επιτυχώς!
                    </p>
                  </div>
                </>
              )}
              {!isLoading &&
                !isSuccessfullUpload &&
                isSuccessfullUpload !== undefined && (
                  <>
                    <div className="inline-flex items-start justify-center gap-2">
                      <div className="mx-auto h-7 w-7 text-red-700">
                        <ExclamationMark />
                      </div>
                      <p className="max-w-sm text-center text-base">
                        {currentAttachment === undefined
                          ? 'Επιλέξτε πρώτα ένα αρχείο!'
                          : 'Υπήρξε κάποιο πρόβλημα με το ανέβασμα του αρχείου.'}
                      </p>
                    </div>
                  </>
                )}
            </div>
            <div className="flex items-center justify-end space-x-2 rounded-b border-t border-gray-200 bg-gray-100 p-6 print:hidden">
              <button
                onClick={handleClose}
                type="button"
                className="inline-flex items-center rounded-full border border-primary bg-gray-50 px-4 py-2 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                <span>Κλείσιμο</span>
              </button>

              <button
                onClick={() => {
                  handleUploadAttachments({
                    appointment,
                    attachment: currentAttachment!,
                  });
                  setIsLoading(true);
                }}
                data-test="remove-modal-cancel-button"
                type="button"
                className="inline-flex items-center rounded-full border border-primary bg-primary px-4 py-2 text-center text-base font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                <span>Προσθήκη</span>
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default AddAttachmentModal;
