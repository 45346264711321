import { ReactNode } from 'react';

export interface Point {
  id: number;
  name: string;
}

export interface Service {
  id: number;
  name: string;
}

export interface ServiceGroup {
  id: number;
  name: string;
}

export interface PointsType {
  active: boolean;
  digital: boolean;
  address: string;
  contact_information: string;
  description: string;
  id: number;
  metadata: Array<any>;
  name: string;
}

export interface InitialFormType {
  serviceGrp: ServiceGroupType;
  service: ServiceType;
  point: PointsType;
  id: number;
}

export interface InitialUserSelectionType {
  serviceGrp: ServiceGroupType;
  service: ServiceType;
  point: PointsType;
  start: string;
  end: string;
}

export interface FilteredServices {
  id: number;
  name: string;
}

export interface ServiceCustomization {
  point: number;
  duration: number;
  service_type: ServiceTypeEnum;
  eopyy_coverage: boolean;
  guidelines: string;
  price: string;
  final_online_status: number;
}

export interface ServiceType {
  id: string;
  name: string;
  description: string;
  metadata: Array<any>;
  service_type: ServiceTypeEnum;
  duration: number;
  price: string;
  points: Array<PointsType>;
  service_groups: ServiceGroupType[];
  guidelines: string;
  eopyy_coverage: boolean;
  final_online_status?: StatusType;
  service_customizations?: ServiceCustomization[];
  arrival_policies: ArrivalPolicyType[];
}

export interface ServiceGroupType {
  id: string;
  name: string;
  description: string;
  metadata: {};
  active: boolean;
}

export type ArrivalPolicyType = {
  point: number;
  policy: PolicyType;
  service: number;
};

export type PolicyType = {
  id: number;
  name: string;
  minutes_earlier: number;
  is_default_policy: boolean;
};

export type VisitorInfoFormType = {
  first_name: string;
  last_name: string;
  insurance_provider: InsuranceProvidersType | null;
  has_foreign_ssrn: boolean;
  ssrn: string;
  phone_number: string;
  email: string;
  notes: string;
  tos_accepted: boolean;
  email_notifications_accepted: boolean;
  sms_notifications_accepted: boolean;
  escort: boolean;
  escort_first_name: string;
  escort_last_name: string;
  escort_phone: string;
};

export type VisitorInfoApiType = {
  id: string;
  name: string;
  phone: string;
  email: string;
  notes: string;
  has_foreign_ssrn: boolean;
  ssrn: string;
  insurance_provider: number | null;
  tos_accepted: boolean;
  email_notifications_accepted: boolean;
  sms_notifications_accepted: boolean;
};

export type Slots = {
  start: string;
  end: string;
};

export interface DateAvailHours {
  start_time: string | null;
  end_time: string | null;
}

export interface AvailabilityHours {
  [date: string]: DateAvailHours[];
}

export type UserInfoFormType = {
  first_name: string;
  last_name: string;
  insurance_provider: InsuranceProvidersType | null;
  ssrn: string;
  phone_number: string;
  email: string;
};

export type UserSettingsType = {
  tos_accepted?: boolean;
  email_notifications_accepted?: boolean;
  sms_notifications_accepted?: boolean;
};

export enum StepEnum {
  BEGIN = 'BEGIN',
  RESERVATIONS = 'RESERVATIONS',
  USER_INPUT = 'USER_INPUT',
  RESERVATIONS_PREVIEW = 'RESERVATIONS_PREVIEW',
  RESERVATIONS_CONFIRM = 'RESERVATIONS_CONFIRM',
}

export type VisitorInfo = {
  name?: string;
  phone?: string;
};

export type VisitorsIDs = {
  primaryContact?: string;
  secondaryContact?: string | null;
};

export interface UploadAppointmentAttachment {
  appointment: AppointmentType;
  attachment: FormData;
}

export interface AppointmentAttachment {
  file: File;
  description: string;
  id: number;
}

export interface AppointmentType {
  id?: string;
  serviceGrp?: ServiceGroupType;
  point?: PointsType;
  service?: ServiceType;
  status?: StatusType;
  start?: Date;
  end?: Date;
  time?: string;
  primary_contact?: VisitorInfoFormType;
  secondary_contact?: VisitorInfo;
  attachments?: AppointmentAttachment[];
  token?: string;
}

export interface LoginFields {
  email: string;
  password: string;
}

export type FormValuesType = Record<string, unknown>;

export type ReservationTimeType = { date: string; hours: string[] }[];

export enum StatusTypeEnum {
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  REQUESTED = 'REQUESTED',
  PREBOOKED = 'PREBOOKED',
}

export enum ServiceTypeEnum {
  UNAVAILABLE = 'UNAVAILABLE',
  PRIORITY_QUEUE = 'PRIORITY_QUEUE',
  CALL = 'CALL',
  CALL_N_ONLINE = 'CALL_N_ONLINE',
}

export interface StatusType {
  id: number;
  name: string;
  status_type: StatusTypeEnum;
  is_final_for_online_reservation: boolean;
}

export interface InsuranceProvidersType {
  id: number;
  name: string;
}

export interface QuestionsType {
  q: string | ReactNode;
  a: string | ReactNode;
}
export interface ServicesDataType {
  serviceGrp: ServiceGroupType[] | undefined;
  services: ServiceType[] | undefined;
  points: PointsType[] | undefined;
}
