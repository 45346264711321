import React, { SVGProps } from 'react';

const HomeConfirmIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
      aria-hidden="true"
    >
      <g clipPath="url(#a)">
        <circle cx="40" cy="40" r="40" fill="#287AC3" />
        <mask
          id="b"
          width="40"
          height="40"
          x="20"
          y="20"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M20 20h40v40H20z" />
        </mask>
        <g mask="url(#b)">
          <path
            fill="#F9FAFB"
            fillRule="evenodd"
            d="M36 26a2 2 0 0 0 2 2h4a2 2 0 1 0 0-4h-4a2 2 0 0 0-2 2Zm-4 0a6 6 0 0 0 6 6h4a6 6 0 0 0 6-6 4 4 0 0 1 4 4v22a4 4 0 0 1-4 4H32a4 4 0 0 1-4-4V30a4 4 0 0 1 4-4Zm15.414 12.586a2 2 0 0 0-2.828 0L38 45.172l-2.586-2.586a2 2 0 1 0-2.828 2.828l4 4a2 2 0 0 0 2.828 0l8-8a2 2 0 0 0 0-2.828Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h80v80H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeConfirmIcon;
