import React, { SVGProps } from 'react';

const CaretDownIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M5.23 7.21a.75.75 0 011.06.02L10 10.88l3.71-3.65a.75.75 0 111.04 1.08l-4.24 4.18a.75.75 0 01-1.04 0L5.21 8.29a.75.75 0 01.02-1.08z"
      ></path>
    </svg>
  );
};

export default CaretDownIcon;
