import React, { SVGProps } from 'react';

const HomeDoctorIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 80 80"
      {...props}
    >
      <g clipPath="url(#a)">
        <circle cx="40" cy="40" r="40" fill="#287AC3" />
        <mask
          id="b"
          width="40"
          height="40"
          x="20"
          y="20"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M20 20h40v40H20z" />
        </mask>
        <g mask="url(#b)">
          <path
            fill="#F9FAFB"
            fillRule="evenodd"
            d="M32.963 27.89c.912-2.308 2.99-3.995 5.527-4.48.726-.142 2.19-.08 2.978.125 1.575.41 3.033 1.39 4.038 2.71.447.596.999 1.738 1.203 2.513.162.59.174.75.174 1.824 0 .98-.025 1.253-.136 1.662-.757 2.742-2.618 4.615-5.298 5.348-.484.136-.701.155-1.756.155-1.116 0-1.253-.013-1.848-.18a7.29 7.29 0 0 1-3.331-1.973c-.906-.924-1.495-1.979-1.867-3.331-.118-.428-.143-.701-.143-1.65-.006-1.24.08-1.78.459-2.723Zm2.81 11.395c.453-.124 1.445-.298 2.035-.354.527-.055 3.399-.037 3.939.019.769.087 1.904.323 1.979.416.16.198.645 2.84.7 3.815l.038.688-.577.292a11.41 11.41 0 0 0-2.86 2.047c-.992.992-1.445 1.774-1.6 2.773-.137.887.155 1.805.806 2.518.664.726 1.185.912 1.545.552.397-.397.322-.72-.267-1.24-.825-.726-.943-1.464-.397-2.513.465-.9 1.954-2.19 3.368-2.909l.435-.217.663.695c2.09 2.183 2.742 4.212 1.675 5.223-.105.105-.41.298-.67.422-.384.192-.502.285-.595.484-.1.204-.106.279-.044.477.087.255.391.478.658.478.329.006 1.222-.496 1.65-.924.614-.62.88-1.272.88-2.165 0-1.545-.917-3.288-2.673-5.068l-.626-.64-.038-.924a16.597 16.597 0 0 0-.409-2.897c-.056-.235-.087-.434-.068-.453.05-.05 1.296.608 1.898 1.005 2.494 1.644 4.299 4.126 5.05 6.954.378 1.44.403 1.7.434 5.013.037 3.374.025 3.474-.329 3.684-.205.13-.428.13-12.667.13-11.185 0-12.481-.012-12.655-.099-.372-.192-.384-.291-.384-3.138 0-3.636.099-4.554.657-6.241a12.166 12.166 0 0 1 5.478-6.68c.316-.187.589-.324.608-.311.012.012-.15.54-.36 1.166-.577 1.706-1.229 4.392-1.408 5.782-.038.297-.063.347-.211.378-.664.155-1.44.844-1.787 1.57-.198.415-.21.49-.21 1.16 0 .67.012.744.21 1.153.112.242.348.59.515.77 1.39 1.482 3.79 1.085 4.671-.77.192-.41.205-.484.205-1.154 0-.67-.013-.744-.205-1.16-.267-.564-.775-1.097-1.278-1.346-.217-.111-.434-.198-.484-.198-.118 0-.105-.075.242-1.824.391-1.948 1.005-3.989 1.7-5.67l.267-.639.496-.13Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h80v80H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeDoctorIcon;
