import { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import {
  CloseIcon,
  ExclamationMark,
  TickIcon,
  CloseBurgerIcon,
} from 'components/svgs';
import { ServiceType } from 'types';

interface Props {
  onClose: () => void;
  onAppDelete: () => void;
  service: ServiceType;
  date: Date;
  time?: string;
  open: boolean;
}

const RemovalModal = ({
  open = false,
  onClose,
  service,
  date,
  time,
  onAppDelete,
}: Props) => {
  const [isOpen, setIsOpen] = useState(open);
  const [isSuccessfullDelete, setIsSuccessfullDelete] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = (): void => {
    setIsOpen(false);
    onClose?.();
  };

  useEffect(() => {
    // cleanup state when modal closes
    return () => {
      setIsSuccessfullDelete(false);
    };
  }, [open]);

  return (
    <Transition appear show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex flex-col items-center justify-center p-4 print:absolute print:h-auto"
        onClose={handleClose}
        open={isOpen}
      >
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="relative max-h-full w-full max-w-lg"
        >
          <Dialog.Panel className="relative flex max-h-full flex-col rounded-lg bg-white shadow print:block print:h-auto print:rounded-none print:shadow-none">
            <div className="flex items-center justify-between rounded-t border-b bg-gray-100 p-5">
              <Dialog.Title
                as="h3"
                className="flex-1 pl-4 text-center text-xl font-medium text-gray-900"
              >
                Aκύρωση του Ραντεβού
              </Dialog.Title>
              <button
                onClick={handleClose}
                type="button"
                className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 transition-all hover:bg-blue-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-200 print:hidden"
              >
                <CloseIcon className="h-5 w-5" />
              </button>
            </div>
            {!isSuccessfullDelete ? (
              <>
                <div className="mx-auto max-w-full space-y-4 p-6">
                  <div className="mx-auto h-10 w-10 text-red-600">
                    <ExclamationMark />
                  </div>
                  <p className="max-w-sm text-center">
                    Θέλετε σίγουρα να ακυρώσετε το ραντεβού σας για{' '}
                    <span className="font-bold">{service?.name}</span> στις{' '}
                    <span className="font-bold">
                      {new Date(date)?.toLocaleDateString('el-GR')} {time}
                    </span>
                    ;
                  </p>
                </div>
                <div className="flex items-center justify-end space-x-2 rounded-b border-t border-gray-200 bg-gray-100 p-6 print:hidden">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="inline-flex items-center rounded-full border border-primary bg-gray-50 px-4 py-2 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
                  >
                    <span>Όχι το κρατάμε</span>
                  </button>

                  <button
                    onClick={() => {
                      onAppDelete();
                      setIsSuccessfullDelete(true);
                    }}
                    data-test="remove-modal-cancel-button"
                    type="button"
                    className="inline-flex items-center rounded-full border border-primary bg-primary px-4 py-2 text-center text-base font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
                  >
                    <span>Ναι, το ακυρώνω</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="mx-auto max-w-full space-y-4 p-6">
                  <div className="mx-auto h-10 w-10 text-green-700">
                    <TickIcon />
                  </div>
                  <p className="max-w-sm text-center">
                    Το ραντεβού σας για{' '}
                    <span className="font-bold">{service?.name}</span> στις{' '}
                    <span className="font-bold">
                      {new Date(date)?.toLocaleDateString('el-GR')} {time}
                    </span>{' '}
                    ακυρώθηκε επιτυχώς.
                  </p>
                </div>
                <div className="flex items-center justify-end space-x-2 rounded-b border-t border-gray-200 bg-gray-100 p-6 print:hidden">
                  <button
                    onClick={handleClose}
                    type="button"
                    data-test="remove-modal-close-button"
                    className="inline-flex items-center rounded-full border border-primary bg-primary px-4 py-2 text-center text-base font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
                  >
                    <span>Κλείσιμο</span>

                    <CloseBurgerIcon className="ml-2.5 h-6 w-6" />
                  </button>
                </div>
              </>
            )}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default RemovalModal;
