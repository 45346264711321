import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { QuestionsType } from 'types';

export interface Props {
  category: string;
  questions: QuestionsType[];
}

export default function Accordions({ category, questions }: Props) {
  return (
    <>
      <div>
        <p className=" w-full text-center text-xl font-bold sm:text-left md:text-2xl xl:mx-auto xl:w-10/12">
          Κατηγορία: {category}
        </p>
        <div className="relative mb-16 mt-5 flex w-full flex-col flex-wrap items-center justify-center rounded-3xl border border-gray-200  bg-gradient-to-br from-blue-200 to-gray-50 px-4 py-10 pb-10 text-lg text-gray-800 md:mt-10 xl:mx-auto xl:w-10/12 xl:text-xl">
          <Accordion
            transition
            transitionTimeout={200}
            className="flex w-full flex-col gap-5 px-0 md:px-10"
          >
            {questions.map((question: QuestionsType) => (
              <AccordionItem
                header={
                  <p
                    className="w-xl px-3 py-2 text-center
                  font-bold sm:text-left"
                  >
                    {question.q}
                  </p>
                }
                className="w-full rounded-xl px-3 py-2 text-center text-black sm:text-left"
                key={crypto.randomUUID()}
              >
                <p className="w-xl rounded-ee-xl rounded-es-xl bg-gray-50 px-5 py-4 text-left text-base md:text-lg">
                  {question.a}
                </p>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
}
