import React from 'react';

import shortLogo from 'img/short-logo.png';

export default function Footer() {
  return (
    <footer
      data-aos-once="true"
      data-aos="fade-up"
      data-aos-duration="1000"
      className="bg-gray-100 print:hidden"
      data-test="footer"
    >
      <div className="mx-auto flex max-w-screen-2xl flex-col items-center justify-center px-4 py-7 md:py-8 lg:px-10">
        <img
          src={shortLogo}
          alt="HHG short version of logo"
          className="mx-auto mb-10 h-12 md:mb-6"
        />

        <div className="mb-20 flex max-w-lg flex-col flex-wrap items-center justify-center space-y-4 text-lg text-gray-800 md:mb-16 md:flex-row md:space-x-10 xl:text-xl">
          <a
            rel="noreferrer"
            href="https://www.hhg.gr/el/"
            target="_blank"
            className="transition-all hover:font-bold hover:text-gray-900 first-of-type:md:mt-4"
          >
            Σημεία Ομίλου
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.hhg.gr/el/επικοινωνία"
            className="transition-all hover:font-bold hover:text-gray-900"
          >
            Επικοινωνία
          </a>
        </div>

        <div className="flex min-w-full flex-col items-center justify-center space-y-10 text-base lg:flex-row lg:justify-between lg:space-y-0 xl:text-lg">
          <div className="flex flex-col items-center justify-center space-x-4 md:flex-row">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.hhg.gr/el/πολιτική-για-τα-cookies"
              className="transition-all hover:font-bold hover:text-gray-900"
            >
              Ρυθμίσεις Cookies
            </a>
            <span className="text-2xl font-black">·</span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.hhg.gr/el/πολιτική-προστασίας-προσωπικών-δεδομένων"
              className="transition-all hover:font-bold hover:text-gray-900"
            >
              Πολιτική Απορρήτου
            </a>
          </div>
          <p>© Copyright 2023 HHG. All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}
