import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import ReservationSuccessButtons from './ReservationSuccessButtons';
import Appointment from '../../components/Appointment';
import ReservationsConfirmMessage from './ReservationsConfirmMessage';
import useStore from 'store';
import { AppointmentType } from 'types';
import useStoreServices from 'storeServices';

export default function ReservationsConfirm() {
  const {
    appointments,
    setRemovalModalState,
    removalModalState,
    setAddAttachmentModalState,
    addAttachmentModalState,
  } = useStore();

  const { availableArrivalPolicies } = useStoreServices();
  const { isOpen } = removalModalState || addAttachmentModalState;

  const navigate = useNavigate();

  // redirect to home page if no appointments in state and modal is closed
  useEffect(() => {
    localStorage.setItem('myAppointment', JSON.stringify(appointments));
    if (isEmpty(appointments) && !isOpen) {
      navigate('/');
    }
  }, [appointments, navigate, isOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <div>
        <div
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-duration="1000"
          className="print:hidden"
        >
          <ReservationsConfirmMessage reservedApps={appointments} />
        </div>

        <h2 className="mb-6 text-center text-2xl font-bold">
          Προγραμματισμένα Ραντεβού
        </h2>

        {appointments?.map((appointment: AppointmentType) => (
          <Appointment
            key={appointment.id ?? crypto.randomUUID()}
            appointment={appointment}
            policies={availableArrivalPolicies}
            onDelete={() => {
              setRemovalModalState({ isOpen: true, appointment });
            }}
            onAddAttachment={() => {
              setAddAttachmentModalState({ isOpen: true, appointment });
            }}
          />
        ))}

        <ReservationSuccessButtons />
      </div>
    </>
  );
}
