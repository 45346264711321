import React, { SVGProps } from 'react';

const BuildingIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#287AC3"
        fillRule="evenodd"
        d="M3.6 0h9.6c1.325 0 2.4 1.162 2.4 2.595v15.568c.663 0 1.2.581 1.2 1.298 0 .716-.537 1.297-1.2 1.297h-3.8a1 1 0 0 1-1-1v-2.892c0-.717-.537-1.297-1.2-1.297H7.2c-.663 0-1.2.58-1.2 1.297v2.892a1 1 0 0 1-1 1H1.2c-.663 0-1.2-.58-1.2-1.297s.537-1.298 1.2-1.298V2.595C1.2 1.162 2.275 0 3.6 0Zm3.6 3.892v2.595H4.8V3.892h2.4Zm-2.4 5.19v2.594h2.4V9.082H4.8Zm7.2-5.19v2.595H9.6V3.892H12Zm-2.4 5.19v2.594H12V9.082H9.6Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default BuildingIcon;
