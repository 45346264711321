import React from 'react';
import { Field, ErrorMessage } from 'formik';

interface InputFieldProps {
  label: string;
  labelName: string;
  name: string;
  type: string;
  errorMessage?: string;
  required?: boolean;
  [x: string]: any;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  labelName,
  name,
  type,
  errorMessage,
  ...props
}) => {
  return (
    <div>
      <label
        htmlFor={labelName}
        className="textGray900 mb-1.5 block text-sm md:text-base"
      >
        {label}
      </label>
      <Field
        id={name}
        name={name}
        type={type}
        className="borderGray400 bgWhite textGray900 block w-full rounded-full border p-4 text-lg focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
        {...props}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="mt-2 text-xs text-red-600"
      />
    </div>
  );
};

export default InputField;
