import { isEmpty } from 'lodash';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  serviceGrp: Yup.object()
    .test('notEmpty', (val, ctx) => {
      return isEmpty(val)
        ? ctx.createError({
            message:
              'Παρακαλούμε επιλέξτε πρώτα μια ομάδα υπηρεσιών για να συνεχίσετε την αναζήτηση.',
          })
        : true;
    })
    .required(
      'Παρακαλούμε επιλέξτε πρώτα μια ομάδα υπηρεσιών για να συνεχίσετε την αναζήτηση.',
    ),
  service: Yup.object().required(
    'Παρακαλούμε επιλέξτε πρώτα μια υπηρεσία για να συνεχίσετε την αναζήτηση.',
  ),
  point: Yup.object().required(
    'Παρακαλούμε επιλέξτε πρώτα ενα σημείο εξυπηρέτησης για να συνεχίσετε την αναζήτηση.',
  ),
});

export default validationSchema;
