import {
  ExclamationMark,
  ServicesIcon,
  SimpleDoctorIcon,
  BuildingIcon,
  LocationIcon,
  CalendarIcon,
  ClockIcon,
  BinIcon,
  AdditionIcon,
  CaretDownIcon,
  CaretRightIcon,
} from 'components/svgs';
import { AppointmentType, PolicyType } from 'types';
import useStore from 'store';
import StatusLabel from 'components/StatusLabel';
import { isEmpty } from 'lodash';
import { getCustomizations, getArrivalPolicy } from 'helpers';
import { useState } from 'react';

interface Props {
  appointment: AppointmentType;
  policies: PolicyType[];
  onDelete: () => void;
  onAddAttachment: () => void;
  onEdit?: () => void;
}

function getTimeSlot(
  startDate: string | number | Date | undefined,
  endDate: string | number | Date | undefined,
) {
  if (!startDate || !endDate) {
    return '';
  }
  const start = new Date(startDate);
  const end = new Date(endDate);

  const getFormattedTime = (date: Date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return `${getFormattedTime(start)} - ${getFormattedTime(end)}`;
}

export default function Appointment({
  appointment,
  policies,
  onDelete,
  onAddAttachment,
  onEdit,
}: Props) {
  const { setIsInstuctionsModalOpen, setInstructionModalText } = useStore();
  const {
    service,
    point,
    start,
    end,
    primary_contact,
    secondary_contact,
    status,
  } = appointment;

  const guidelines =
    getCustomizations(service, point)?.guidelines || service?.guidelines;

  const escortName = secondary_contact?.name?.trim().split(' ');

  const policy =
    getArrivalPolicy(appointment)?.policy.minutes_earlier ||
    policies.find((policy) => policy.is_default_policy === true)
      ?.minutes_earlier;

  const [isPrimaryOpen, setIsPrimaryOpen] = useState(false);
  const [isSecondaryOpen, setIsSecondaryOpen] = useState(false);

  const togglePrimary = () => setIsPrimaryOpen(!isPrimaryOpen);
  const toggleSecondary = () => setIsSecondaryOpen(!isSecondaryOpen);

  const CaretIcon = ({ isOpen }: { isOpen: boolean }) =>
    isOpen ? (
      <CaretDownIcon className="ml-2 h-5 w-5" />
    ) : (
      <CaretRightIcon className="ml-2 h-5 w-5" />
    );

  return (
    <section
      data-aos="fade-in"
      data-aos-once="true"
      data-aos-duration="1500"
      className="w-100 mb-10 rounded-3xl bg-gray-50 px-4 py-10 text-center lg:text-left xl:px-14 2xl:px-20"
    >
      <div className="mb-6 flex flex-col items-center justify-center md:flex-row md:items-start md:justify-between">
        <div className="flex flex-col md:items-start">
          <h1
            data-test="appointment-service-name-h1"
            className=" text-lg font-bold print:hidden md:mb-4 md:text-xl lg:mb-6 lg:text-2xl"
          >
            {service?.name}
            <StatusLabel status={status} />
          </h1>
          {policy && policy > 0 ? (
            <p className="flex w-full flex-col gap-0 text-sm font-normal sm:flex-row sm:gap-2 lg:text-base">
              <span className="font-bold leading-6">Πολιτική Άφιξης: </span>
              <span className="leading-normal underline">
                {policy} λεπτά νωρίτερα!
              </span>
            </p>
          ) : (
            <p className="text-base font-normal">
              Το ραντεβού εξυπηρετείται την αναγραφόμενη ώρα.
            </p>
          )}
        </div>
        <div className="group flex flex-row items-center space-x-1.5 hover:text-gray-600">
          <div
            className={`h-5 w-5 flex-shrink-0 text-blue-900 ${
              guidelines !== '' ? 'group-hover:text-gray-600' : ''
            } print:hidden md:h-5 md:w-5`}
          >
            <ExclamationMark />
          </div>
          <div className="text-xs md:text-left md:text-base">
            {guidelines !== '' ? (
              <button
                onClick={() => {
                  setIsInstuctionsModalOpen(true);
                  setInstructionModalText(guidelines as string);
                }}
                type="button"
                className="font-bold leading-10 text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200 group-hover:text-gray-600 print:hidden"
                aria-controls="navbar-default"
                aria-expanded="false"
              >
                Δείτε τις οδηγίες προετοιμασίας
              </button>
            ) : (
              <p className="font-bold text-blue-600">
                Δεν υπάρχουν οδηγίες προετοιμασίας
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:gap-10">
        <div className="space-y-4 rounded-lg bg-white p-5">
          <div className="space-y-1 text-left">
            <span className="text-xs">Kατηγορία</span>
            <div className="flex flex-row items-start space-x-3">
              <ServicesIcon className="mt-1 h-5 w-5 flex-shrink-0" />
              <span className="text-lg font-medium">
                {service?.service_groups[0]?.name}
              </span>
            </div>
          </div>
          <div className="space-y-1 text-left">
            <span className="text-xs">Υπηρεσία</span>
            <div className="flex flex-row items-start space-x-3">
              <SimpleDoctorIcon className="mt-0.5 h-6 w-5 flex-shrink-0" />
              <span className="text-lg font-medium">{service?.name}</span>
            </div>
          </div>
        </div>
        <div className="space-y-4 rounded-lg bg-white p-5">
          <div className="space-y-1 text-left">
            <span className="text-xs">Περιοχή</span>
            <div className="flex flex-row items-start space-x-3">
              <BuildingIcon className="mt-0.5 h-6 w-5 flex-shrink-0" />
              <span
                className="text-lg font-medium"
                data-test="appointments-point-name"
              >
                {point?.name}
              </span>
            </div>
          </div>

          <div className="space-y-1 text-left">
            <span className="text-xs">Διεύθυνση</span>
            <div className="flex flex-row items-start space-x-3">
              <LocationIcon className="mt-0.5 h-6 w-6 flex-shrink-0" />
              <span
                className="text-lg font-medium"
                data-test="appointments-address"
              >
                {point?.address}
              </span>
            </div>
          </div>
        </div>
        <div className="space-y-4 rounded-lg bg-white p-5">
          <div className="space-y-1 text-left">
            <span className="text-xs">Ημερομηνία</span>
            <div className="flex flex-row items-start space-x-3">
              <CalendarIcon className="mt-0.5 h-6 w-6 flex-shrink-0" />
              <span
                data-test="appointments-date"
                className="text-lg font-medium"
              >
                {new Date(start!)?.toLocaleDateString('el-GR')}
              </span>
            </div>
          </div>

          <div className="space-y-1 text-left">
            <span className="text-xs">Ώρα</span>
            <div className="flex flex-row items-start space-x-3">
              <ClockIcon className="mt-0.5 h-6 w-6 flex-shrink-0" />
              <span
                className="text-lg font-medium"
                data-test="appointments-time"
              >
                {getTimeSlot(start, end)}
              </span>
            </div>
          </div>
        </div>
        {primary_contact?.notes && (
          <div className="col-span-1 mb-5 rounded-lg bg-white ps-5 sm:col-span-2 lg:col-span-3">
            <p className="my-3 text-lg font-bold">Λεπτομέρειες θέματος</p>
            <p className="text-left">{primary_contact?.notes!}</p>
          </div>
        )}
      </div>
      {primary_contact && (
        <div className="pt-5">
          <button
            onClick={togglePrimary}
            className="flex w-full items-center text-left text-lg font-bold"
          >
            <span className="mr-2">ΣΤΟΙΧΕΙΑ ΕΠΙΣΚΕΠΤΗ</span>
            <CaretIcon isOpen={isPrimaryOpen} />
          </button>
          {isPrimaryOpen && (
            <div className="grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-5 xl:gap-x-10">
              <div className="space-y-0.5 px-5 py-2 text-left">
                <span className="text-xs">ΟΝΟΜΑ</span>
                <p className="text-lg font-medium">
                  {primary_contact.first_name}
                </p>
              </div>
              <div className="space-y-0.5 px-5 py-2 text-left">
                <span className="text-xs">ΕΠΩΝΥΜΟ</span>
                <p className="text-lg font-medium">
                  {primary_contact.last_name}
                </p>
              </div>
              <div className="space-y-0.5 px-5 py-2 text-left">
                <span className="text-xs">EMAIL</span>
                <p className="text-lg font-medium">{primary_contact.email}</p>
              </div>
              <div className="space-y-0.5 px-5 py-2 text-left">
                <span className="text-xs">ΤΗΛΕΦΩΝΟ</span>
                <p className="text-lg font-medium">
                  {primary_contact.phone_number}
                </p>
              </div>
              {primary_contact.insurance_provider && (
                <div className="space-y-0.5 px-5 py-2 text-left">
                  <span className="text-xs">ΑΣΦΑΛΕΙΑ</span>
                  <p className="text-lg font-medium">
                    {primary_contact.insurance_provider.name}
                  </p>
                </div>
              )}
              {primary_contact.ssrn && (
                <div className="space-y-0.5 px-5 py-2 text-left">
                  <span className="text-xs">ΑΜΚΑ</span>
                  <p className="text-lg font-medium">{primary_contact.ssrn}</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {secondary_contact && (
        <div className="pt-5">
          <button
            onClick={toggleSecondary}
            className="flex w-full items-center text-left text-lg font-bold"
          >
            <span className="mr-2">ΣΤΟΙΧΕΙΑ ΣΥΝΟΔΟΥ</span>
            <CaretIcon isOpen={isSecondaryOpen} />
          </button>
          {isSecondaryOpen && (
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:gap-10">
              <div className="space-y-0.5 px-5 py-2 text-left">
                <span className="text-xs">ΟΝΟΜΑ</span>
                <p className="text-lg font-medium">{escortName?.[0]}</p>
              </div>
              <div className="space-y-0.5 px-5 py-2 text-left">
                <span className="text-xs">ΕΠΩΝΥΜΟ</span>
                <p className="text-lg font-medium">{escortName?.[1]}</p>
              </div>
              <div className="space-y-0.5 px-5 py-2 text-left">
                <span className="text-xs">ΤΗΛΕΦΩΝΟ</span>
                <p className="text-lg font-medium">{secondary_contact.phone}</p>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="mt-6 flex w-full flex-col items-center justify-between gap-5 print:hidden md:flex-row md:gap-0 md:space-x-6">
        <button
          onClick={onAddAttachment}
          type="button"
          className="flex flex-row items-center space-x-1.5 font-bold text-green-600 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-200 print:hidden"
          data-test="appointment-add-attachments-button"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span>
            {!isEmpty(appointment.attachments)
              ? 'Συνημμένα Αρχεία'
              : 'Προσθήκη αρχείου'}
          </span>
          <AdditionIcon className="h-5 w-5" />
        </button>
        <button
          onClick={onDelete}
          type="button"
          className="flex flex-row items-center space-x-1.5 font-bold text-red-600 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-200 print:hidden"
          data-test="appointment-delete-button"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span>Αφαίρεση</span>
          <BinIcon className="h-5 w-5" />
        </button>
      </div>
    </section>
  );
}
