import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import getToken from 'utils/getToken';
import { encrypt } from 'utils/crypto';

type TokenType = Object | string;

interface ContextInterface {
  token?: TokenType;
  login: (token: TokenType) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<ContextInterface | null>(null);

interface Props {
  children: React.ReactNode;
}

export function AuthProvider({ children }: Props): JSX.Element {
  const location = useLocation();

  const [token, setToken] = React.useState(getToken('token'));

  const login = React.useCallback((userToken: TokenType) => {
    localStorage.setItem('token', encrypt(JSON.stringify(userToken)));
    setToken(userToken);
  }, []);

  const logout = React.useCallback(() => {
    localStorage.removeItem('token');
    setToken('');
  }, []);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const bookingToken = params.get('booking-token');

    if (bookingToken) {
      login(bookingToken);

      params.delete('booking-token');
      const paramsString = params.toString();
      const newUrl = `${location.pathname}${
        paramsString ? `?${paramsString}` : ''
      }`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [login, location.search, location.pathname]);

  const value = React.useMemo(
    () => ({
      token,
      login,
      logout,
    }),

    [token, login, logout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined || context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const AuthLayout = () => {
  const { token } = useAuth();
  const location = useLocation();

  if (token) {
    const params = new URLSearchParams(location.search);
    const nextPage = params.get('next');
    return <Navigate to={nextPage || 'account'} replace />;
  }

  return <Outlet />;
};

export const ProtectedLayout = () => {
  const { token } = useAuth();
  const location = useLocation();

  if (!token) {
    const nextPage = location.pathname;
    return nextPage !== '' && nextPage !== '/' ? (
      <Navigate to={`error-login/?next=${nextPage}`} replace />
    ) : (
      <Navigate to="error-login" replace />
    );
  }

  return <Outlet />;
};
