import {
  AppointmentType,
  ArrivalPolicyType,
  Point,
  ServiceCustomization,
  ServiceType,
  ServiceGroupType,
  ServiceTypeEnum,
  VisitorInfoApiType,
  VisitorInfoFormType,
  InsuranceProvidersType,
} from 'types';

export function getCustomizations(
  service: ServiceType | undefined,
  point: Point | undefined,
) {
  const customizations = service?.service_customizations?.find(
    (custom: ServiceCustomization) => custom?.point === point?.id,
  );

  return customizations;
}

export function checkServiceOnlineCustomization(
  service: ServiceType | undefined,
): boolean | undefined {
  return service?.service_customizations?.some(
    (customization) =>
      customization.service_type === ServiceTypeEnum.CALL_N_ONLINE,
  );
}

export function getServices(
  services: ServiceType[],
  serviceGroup: ServiceGroupType | undefined,
) {
  return services.filter((service: ServiceType) => {
    return service.service_groups.some(
      (group: ServiceGroupType) => group.id === serviceGroup?.id,
    );
  });
}

export function resetSuccessfulMessage(
  state: React.Dispatch<React.SetStateAction<boolean>>,
) {
  setTimeout(() => state((prev) => !prev), 4000);
}

export function getArrivalPolicy(appointment: AppointmentType) {
  const policy = appointment.service?.arrival_policies?.find(
    (policy) => policy.point === appointment.point?.id,
  );

  return policy as ArrivalPolicyType;
}

export function luhn_check(value: string) {
  let checksum = 0;
  for (let i = 0; i < value.length; i++) {
    let digit = parseInt(value[i]);
    if (i % 2 !== 0) {
      digit *= 2;
      digit -= digit > 9 ? 9 : 0;
    }
    checksum += digit;
  }
  return checksum % 10 === 0;
}

export function mapApiContactToVisitorInfo(
  contact: VisitorInfoApiType,
  availableInsuranceProviders: InsuranceProvidersType[],
): VisitorInfoFormType {
  const [first_name, last_name] = (contact.name || '').split(' ');
  const insuranceProviderName = contact.insurance_provider
    ? availableInsuranceProviders.find(
        (provider) => provider.id === contact.insurance_provider,
      )?.name
    : undefined;
  return {
    first_name: first_name || '',
    last_name: last_name || '',
    phone_number: contact.phone || '',
    email: contact.email || '',
    notes: contact.notes || '',
    has_foreign_ssrn: contact.has_foreign_ssrn || false,
    ssrn: contact.ssrn || '',
    insurance_provider: insuranceProviderName
      ? {
          id: contact.insurance_provider!,
          name: insuranceProviderName,
        }
      : null,
    tos_accepted: contact.tos_accepted || false,
    email_notifications_accepted: contact.email_notifications_accepted || false,
    sms_notifications_accepted: contact.sms_notifications_accepted || false,
    escort: false,
    escort_first_name: '',
    escort_last_name: '',
    escort_phone: '',
  };
}
