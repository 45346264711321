import { Accordion } from '@szhsin/react-accordion';
import Accordions from 'components/Accordions';
import { TurnBackIcon } from 'components/svgs';

const appointmentQuestions = [
  {
    q: 'Πώς μπορώ να κλείσω ένα ραντεβού;',
    a: (
      <span>
        Για να κλείσεις ραντεβού το μόνο που χρειάζεται είναι να ακολουθήσεις 4
        απλά βήματα.
        <ol className="list-auto ps-5">
          <li>
            Επέλεξε την γενική κατηγορία της εξέτασης που θες (ομάδα υπηρεσιών),
            την υπηρεσία για την οποία ενδιαφέρεσαι και το σημείο εξυπηρέτησης
            που σε εξυπηρετεί.Επέλεξε την γενική κατηγορία της εξέτασης που θες
            (ομάδα υπηρεσιών), την υπηρεσία για την οποία ενδιαφέρεσαι και το
            σημείο εξυπηρέτησης που σε εξυπηρετεί.
          </li>
          <li>Επίλεξε την ημερομηνία και την ώρα που θες</li>
          <li>
            Συμπλήρωσε τα στοιχεία σου (Ονοματεπώνυμο, Ασφάλεια, ΑΜΚΑ, τηλέφωνο
            επικοινωνίας, email, κλπ)
          </li>
          <li>Το ραντεβού σου έχει καταχωρηθεί!</li>
        </ol>
      </span>
    ),
  },
  {
    q: 'Τι χρειάζεται για να κλείσω ραντεβού;',
    a: `Για τη διαδικασία του ραντεβού χρειάζεται να δηλωθεί το ΑΜΚΑ σου,
  ένα τηλέφωνο για να μπορέσει να έρθει σε επικοινωνία ο γιατρός
  μαζί σου αν χρειαστεί και μια διεύθυνση ηλεκτρονικού ταχυδρομίου
  για να ενημερώνεσαι σχετικά με την κατάσταση του ραντεβού σου.
  Στην περίπτωση που κλείνεις ραντεβού για κάποιο τρίτο πρόσωπο
  μπορείς να επιλέξεις διαφορετικά στοιχεία επικοινωνίας από τα δικά
  σου.`,
  },
  {
    q: 'Πώς μπορώ να είμαι σίγουρος ότι το ραντεβού μου έχει κλειστεί;',
    a: `Εάν έχεις ήδη λάβει email το οποίο σε ενημερώνει ότι η κράτηση σου
  άλλαξε κατάσταση σε "Δεσμεύθηκε", τότε έχεις ολοκληρώσει σωστά τη
  διαδικασία. Σε αντίθετη περίπτωση θα λάβεις σε σύντομο διάστημα
  την επιβεβαίωση του ραντεβού σου μέσω email & sms με όλες τις
  απαραίτητες πληροφορίες!`,
  },
  {
    q: 'Πώς μπορώ να αλλάξω ή να ακυρώσω το ραντεβού μου;',
    a: `Έχεις την δυνατότητα μόνο να ακυρώσεις το ραντεβού. Αν έχεις
  δημιουργήσει προσωπικο λογαριασμό μπορείς να ακυρώσεις το ραντεβού
  σου επιλέγοντας "Προφιλ" "Κρατήσεις" "Αφαιρεση" (στο ραντεβου που
  επιθυμείς να ακυρώσεις). Σε περίπτωση που δημιούργησες το ραντεβού
  ως επισκέπτης μπορείς να επικοινωνήσεις απευθείας στο τηλέφωνο του
  ιατρείου που έλαβες στο επιβεβαιωτικό email.`,
  },
  {
    q: 'Πριν κλείσω το ραντεβού πως μπορώ να μάθω περισσότερα;',
    a: `Κατά τη διαδικασία του ηλεκτρονικού ραντεβού μπορείς να δεις τα
              στοιχεία επικοινωνίας του εκάστοτε σημείου εξυπηρέτησης και να
              επικοινωνήσεις τηλεφωνικά. Επίσης μπορείς να πατήσεις την επιλογή
              "Σε καλούμε" από το μενού και να συμπληρώσεις το τηλεφωνό σου.
              Ένας διαθέσιμος εκπρόσωπος θα σε καλέσει δωρεάν.`,
  },
];
const accountQuestions = [
  {
    q: 'Έχω ξεχάσει τον κωδικό μου τι πρέπει να κάνω για να συνδεθώ;',
    a: `Εάν δε θυμάσαι τον κωδικό σου μπορείς να κάνεις ανάκτηση και να
              σου σταλεί νέος κωδικός στο email σου. Στην ενότητα "Είσοδος
              Χρήστη" θα πατήσεις στην επιλογή “Ξεχάσατε τον κωδικό σας;”. Θα
              πληκτρολογήσεις το email σου και θα λάβεις email με τίτλο
              "Επαναφορά κωδικού πρόσβασης". Το email αυτό περιέχει ένα κουμπί
              "Επαναφορά κωδικού πρόσβασης” το οποίο σε οδηγεί σε μια σελίδα
              όπου μπορείς να εισάγεις το νεο κωδικό σου. Αφού ολοκληρώσεις όλα
              τα προηγούμενα βήματα θα μπορείς πλέον να συνδεθείς με τον νεο σου
              κωδικό.`,
  },
  {
    q: 'Πώς μπορώ να δω τα ραντεβού που έχω κλείσει;',
    a: `Εφόσον συνδεθείς στον προσωπικό σου λογαριασμό μπορείς να δεις όλα
              τα μελλοντικά σου ραντεβού μέσω του Προφίλ σου πατώντας στο κουμπί
              "Κρατήσεις".`,
  },
  {
    q: `Πριν κλείσω το ραντεβού μπορώ να ρωτήσω κάτι το γιατρό/
                επαγγελματία υγείας;`,
    a: `Κατά τη διαδικασία του ηλεκτρονικού ραντεβού στο βήμα συμπλήρωσης
              των στοιχείων σου μπορείς να γράψεις όλα σου τα ερωτήματα στο
              πεδίο “ΛΕΠΤΟΜΕΡΕΙΕΣ ΘΕΜΑΤΟΣ”. Ο γιατρός ή επαγγελματίας υγείας θα
              λάβει άμεσα το αίτημα για το ραντεβού σου, θα δει τις ερωτήσεις
              σου και θα σου λύσει όλες τις απορίες σχετικά με την επίσκεψη σου.`,
  },
];

export default function Faq() {
  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="py-10 text-center lg:mb-10"
      >
        <div className="mt-10 flex w-full flex-1 flex-col items-start sm:flex-row">
          <a href="/">
            <TurnBackIcon className="h-10 w-10 cursor-pointer self-start text-blue-600 hover:text-gray-600" />
          </a>
          <h1 className="w-full px-8 pt-4 text-center text-2xl font-bold md:pt-0 md:text-3xl lg:text-4xl xl:mx-auto xl:w-9/12 xl:px-16 2xl:px-32">
            Συχνές Ερωτήσεις
          </h1>
        </div>
      </div>
      <Accordion
        transition
        transitionTimeout={200}
        className="flex w-full flex-col gap-5 px-10"
      >
        <Accordions category="Ραντεβού" questions={appointmentQuestions} />
        <Accordions
          category="Προσωπικός λογαριασμός"
          questions={accountQuestions}
        />
      </Accordion>
    </main>
  );
}
