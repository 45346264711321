interface Props {
  service: string;
}

export default function Service({ service }: Props) {
  if (!service) {
    return <></>;
  }

  return (
    <>
      <h1 className="mb-6 text-lg font-bold print:hidden md:mb-10 md:text-xl lg:mb-6 lg:text-2xl">
        {service}
      </h1>
    </>
  );
}
