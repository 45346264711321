import React, { SVGProps } from 'react';

const CaretRightIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M7.21 5.23a.75.75 0 011.08-.02l4.18 4.24a.75.75 0 010 1.04l-4.18 4.24a.75.75 0 11-1.08-1.04L10.88 10 7.21 6.29a.75.75 0 01-.02-1.06z"
      ></path>
    </svg>
  );
};

export default CaretRightIcon;
