import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Loading } from 'components/Loader';
import { TickIcon } from 'components/svgs';
import { useNavigate } from 'react-router-dom';

const CancelReservation = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [reservationCancelled, setReservationCancelled] = useState(false);
  const [serviceName, setServiceName] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');

  if (!searchParams.get('token') || !searchParams.get('id'))
    navigate('/error404');

  const cancelReservation = async () => {
    try {
      setIsLoading(true);
      if (reservationCancelled) return;
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/cancel-reservation/`,
        {
          id: searchParams.get('id'),
          token: searchParams.get('token'),
        },
      );

      if (response.status === 200) {
        setReservationCancelled(true);
      }
    } catch (err) {
      console.log(err);
      setReservationCancelled(false);
    }
    setIsLoading(false);
  };

  const getReservation = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/api/v1/cancel-reservation/?id=${searchParams.get(
          'id',
        )}&token=${searchParams.get('token')}`,
      );

      if (response.status === 200) {
        const { service, start, status } = response.data;

        setServiceName(service.name);
        setDate(new Date(start)?.toLocaleDateString('el-GR'));
        setTime(
          new Date(start)?.toLocaleTimeString('el-GR', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
          }),
        );

        if (status.status_type === 'CANCELLED') {
          setReservationCancelled(true);
        }

        setIsLoading(false);
      } else {
        navigate('/error404');
      }
    } catch (err) {
      console.log(err);
      navigate('/error404');
    }
  };

  useEffect(() => {
    getReservation();
  });

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-lg rounded-3xl bg-gray-50 px-4 py-10 text-center md:max-w-xl xl:max-w-3xl xl:px-14 2xl:px-20"
      >
        <h1 className="mb-6 text-2xl font-bold md:text-3xl lg:text-4xl">
          Ακύρωση κράτησης
        </h1>
        {isLoading ? (
          <div className="mt-10 flex justify-center">
            <div className="flex">
              <Loading size={150} />
            </div>
          </div>
        ) : (
          <>
            {reservationCancelled ? (
              <div className="flex flex-col items-center gap-3">
                <p className="text-base lg:text-xl">
                  Το ραντεβού σας για{' '}
                  <span className="font-bold">{serviceName}</span> στις{' '}
                  <span className="font-bold">
                    {date} {time}
                  </span>{' '}
                  ακυρώθηκε επιτυχώς.
                </p>
                <span className="text-sm lg:text-xl">
                  Για να κλείσετε νέο ραντεβού, πατήστε{' '}
                  <a href="/" className="text-primary hover:underline">
                    εδώ
                  </a>
                </span>
                <TickIcon className="mt-3 h-14 w-14 text-green-600" />
              </div>
            ) : (
              <div>
                <div className="mb-5 flex flex-col items-center justify-center gap-3">
                  <p className="text-base lg:text-xl">
                    Θέλετε σίγουρα να ακυρώσετε το ραντεβού σας για{' '}
                    <span className="font-bold">{serviceName}</span> στις{' '}
                    <span className="font-bold">{date}</span>{' '}
                    <span className="font-bold">{time}</span>;
                  </p>
                  <div className="flex items-center justify-end space-x-2 rounded-b p-6 print:hidden">
                    <button
                      onClick={() => {
                        navigate('/');
                      }}
                      type="button"
                      className="inline-flex items-center rounded-full border border-primary bg-gray-50 px-4 py-2 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
                    >
                      <span>Όχι το κρατάμε</span>
                    </button>

                    <button
                      onClick={() => {
                        cancelReservation();
                      }}
                      data-test="remove-modal-cancel-button"
                      type="button"
                      className="inline-flex items-center rounded-full border border-primary bg-primary px-4 py-2 text-center text-base font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
                    >
                      <span>Ναι, το ακυρώνω</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default CancelReservation;
