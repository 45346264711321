import React, { SVGProps } from 'react';

const ServicesIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="#287AC3"
        fillRule="evenodd"
        d="M2.4 0h14.4c1.325 0 2.4 1.162 2.4 2.595V5.19c0 1.433-1.075 2.594-2.4 2.594H2.4C1.075 7.784 0 6.623 0 5.19V2.595C0 1.162 1.075 0 2.4 0Zm13.2 5.19c-.663 0-1.2-.581-1.2-1.298 0-.716.537-1.297 1.2-1.297.663 0 1.2.58 1.2 1.297s-.537 1.298-1.2 1.298ZM2.4 10.379h14.4c1.325 0 2.4 1.162 2.4 2.595v2.595c0 1.433-1.075 2.594-2.4 2.594H2.4c-1.325 0-2.4-1.161-2.4-2.595v-2.594c0-1.433 1.075-2.595 2.4-2.595Zm13.2 5.19c-.663 0-1.2-.581-1.2-1.298 0-.716.537-1.297 1.2-1.297.663 0 1.2.58 1.2 1.297s-.537 1.297-1.2 1.297Zm0 0c-.663 0-1.2-.581-1.2-1.298 0-.716.537-1.297 1.2-1.297.663 0 1.2.58 1.2 1.297s-.537 1.297-1.2 1.297Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ServicesIcon;
