import { ExclamationMark } from 'components/svgs';
import { ServiceTypeEnum } from '../types';

interface Props {
  pointContactInformation: string;
  noDigitalAppointmentServiceType: ServiceTypeEnum;
}
export default function NoDigitalAppointment(props: Props) {
  const { pointContactInformation } = props;
  const { noDigitalAppointmentServiceType } = props;

  return (
    <div className="mx-auto my-6 flex max-w-md flex-row items-start justify-center space-x-4 rounded-lg bg-blue-100 p-6 md:max-w-3xl">
      <div className="h-8 w-8 flex-shrink-0 text-blue-900 md:h-9 md:w-9">
        <ExclamationMark />
      </div>
      {noDigitalAppointmentServiceType === ServiceTypeEnum.CALL && (
        <p className="mb-4 flex flex-col text-left print:hidden md:mb-1">
          <span>
            Η υπηρεσία που έχετε επιλέξει δεν εξυπηρετείται ηλεκτρονικά, πρέπει
            να κλείσετε ραντεβού{' '}
            {pointContactInformation ? 'στα παρακάτω στοιχεία.' : 'τηλεφωνικά.'}
          </span>
          {pointContactInformation && (
            <p>
              {pointContactInformation
                ?.split(/\r?\n/)
                .map((singleInfo: string) => (
                  <span key={crypto.randomUUID()}>{singleInfo}</span>
                ))}
            </p>
          )}
        </p>
      )}
      {noDigitalAppointmentServiceType === ServiceTypeEnum.PRIORITY_QUEUE && (
        <p className="mb-4 flex flex-col text-left print:hidden md:mb-1">
          <span>
            Η υπηρεσία που έχετε επιλέξει εξυπηρετείται με σειρά προτεραιότητας,
            θα πρέπει να προσέλθετε στο νοσοκομείο χωρίς κράτηση.
          </span>
        </p>
      )}
      {noDigitalAppointmentServiceType === ServiceTypeEnum.UNAVAILABLE && (
        <p className="mb-4 flex flex-col text-left print:hidden md:mb-1">
          <span>
            Η υπηρεσία που έχετε επιλέξει δεν εξυπηρετείται από αυτό το σημείο
            εξυπηρέτησης.
          </span>
        </p>
      )}
    </div>
  );
}
