import error500 from 'img/error500.svg';

function Error500() {
  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-xl rounded-3xl px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <img src={error500} alt="error500" className="mb-4 w-full" />
        <h1 className="mb-6 text-2xl font-bold md:text-3xl lg:text-4xl">
          Server Error
        </h1>
        <p className="mb-4 text-base sm:text-xl">
          Ο server αντιμετώπισε κάποιο πρόβλημα και δεν κατάφερε να ολοκληρώσει
          το αίτημα σας.
        </p>
        <p className="text-base sm:text-xl">
          Παρακαλούμε δοκιμάστε ξανά αργότερα.
        </p>
      </div>
    </main>
  );
}

export default Error500;
