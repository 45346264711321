import React, { useState } from 'react';
import cn from 'classnames';

import heart from 'img/heart.svg';
import { NextStepIcon, TurnBackIcon } from 'components/svgs';

export default function Guide() {
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const handleButtonClick = (buttonId: string) => {
    setActiveButton(buttonId);
  };

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="py-10 text-center lg:mb-10"
      >
        <div className="mt-10 flex w-full flex-1 flex-col items-start sm:flex-row">
          <a href="/">
            <TurnBackIcon className="h-10 w-10 cursor-pointer self-start text-blue-600 hover:text-gray-600" />
          </a>
          <h1 className="w-full px-8 pt-4 text-center text-2xl font-bold md:pt-0 md:text-3xl lg:text-4xl xl:mx-auto xl:w-9/12 xl:px-16 2xl:px-32">
            Ακολουθήστε τα βήματα του έξυπνου οδηγού και κλείστε ραντεβού άμεσα
            και εύκολα
          </h1>
        </div>
      </div>
      <div className="mb-50 relative mt-10 flex w-full flex-col flex-wrap items-center justify-center rounded-3xl border border-gray-200 bg-gray-150 px-4 py-10 text-lg text-gray-800 md:mb-16 xl:mx-auto xl:w-10/12 xl:text-xl">
        <img
          className="absolute top-[-50px] h-24 w-28 md:-top-8 md:left-5 md:w-32 xl:left-20"
          src={heart}
          alt="heart"
        />
        <h2 className="mx-auto mb-10 mt-20 text-center text-2xl font-bold md:mt-0">
          Για ποιόν θα κλείσετε το ραντεβού?
        </h2>
        <div className="grid justify-items-center gap-4 sm:grid-cols-1 md:max-w-2xl md:grid-cols-2">
          <button
            id="1"
            type="button"
            onClick={() => handleButtonClick('1')}
            className={cn(
              'guide-button w-[400px]',
              activeButton === '1'
                ? 'bg-blue-500 text-white'
                : ' bg-gray-200 hover:bg-blue-100',
            )}
          >
            Για μένα
          </button>
          <button
            id="2"
            type="button"
            onClick={() => handleButtonClick('2')}
            className={cn(
              'guide-button',
              activeButton === '2'
                ? 'bg-blue-500 text-white'
                : ' bg-gray-200 hover:bg-blue-100',
            )}
          >
            Για το παιδί μου
          </button>
          <button
            id="3"
            type="button"
            onClick={() => handleButtonClick('3')}
            className={cn(
              'guide-button',
              activeButton === '3'
                ? 'bg-blue-500 text-white'
                : ' bg-gray-200 hover:bg-blue-100',
            )}
          >
            Για τον/την σύζυγο
          </button>
          <button
            id="4"
            type="button"
            onClick={() => handleButtonClick('4')}
            className={cn(
              'guide-button',
              activeButton === '4'
                ? 'bg-blue-500 text-white'
                : ' bg-gray-200 hover:bg-blue-100',
            )}
          >
            Για ένα γονιό
          </button>
          <button
            id="5"
            type="button"
            onClick={() => handleButtonClick('5')}
            className={cn(
              'guide-button',
              activeButton === '5'
                ? 'bg-blue-500 text-white'
                : ' bg-gray-200 hover:bg-blue-100',
            )}
          >
            Αλλο
          </button>
        </div>
        <a href="/" className="md:ms-auto">
          <button
            type="button"
            className="mt-6 inline-flex items-center self-center rounded-full border border-primary bg-primary px-6 py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 md:self-end"
          >
            <span>Επόμενο</span>
            <NextStepIcon className="ml-2.5 h-6 w-6" />
          </button>
        </a>
      </div>
    </main>
  );
}
