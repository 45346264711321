import { useState, useEffect } from 'react';
import { useAuth } from 'auth/AuthProvider';
import MobileMenu from './MobileMenu';
import logo from 'img/logo.png';
import { ManIcon, BurgerMenuIcon, LogOutIcon } from 'components/svgs';
import { useLocation } from 'react-router-dom';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const { token, logout } = useAuth();
  const isLoggedIn = !!token;
  const handleLogout = () => {
    logout();
  };
  const location = useLocation();
  const [url, setUrl] = useState<string>('');
  useEffect(() => {
    setUrl(location.pathname);
  }, [location, isLoggedIn]);

  const activeLinkClassname = 'text-primary';

  return (
    <header className="bg-gray-100" data-test="header">
      <nav className="mx-auto flex max-w-screen-2xl flex-wrap items-center justify-between px-4 py-7 md:py-8 lg:px-10">
        <a href="/">
          <img src={logo} className="h-7 md:h-10" alt="HHG Logo" />
        </a>

        {/* <!-- mobile menu button --> */}
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          type="button"
          className="ml-3 inline-flex items-center rounded-lg p-2 text-sm text-gray-900 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-200 print:hidden lg:hidden"
          aria-controls="navbar-default"
          aria-expanded="false"
          data-test="burger-menu"
        >
          <span className="sr-only">Open main menu</span>
          <BurgerMenuIcon />
        </button>
        <div className="hidden flex-row items-center space-x-8 lg:flex">
          <ul className="flex flex-row space-x-8 text-xl">
            <li>
              <a
                href="https://www.hhg.gr/el/ανακοινώσεις/γενικές-ανακοινώσεις"
                target="_blank"
                className="relative transition-all hover:before:absolute hover:before:-bottom-3 hover:before:left-1/2 hover:before:h-1 hover:before:w-full hover:before:translate-x-[-50%] hover:before:rounded-full hover:before:bg-primary hover:before:content-['']"
                rel="noreferrer"
              >
                Ανακοινώσεις
              </a>
            </li>
            <li>
              <a
                href="/callback"
                target="_self"
                className={
                  "relative transition-all hover:before:absolute hover:before:-bottom-3 hover:before:left-1/2 hover:before:h-1 hover:before:w-full hover:before:translate-x-[-50%] hover:before:rounded-full hover:before:bg-primary hover:before:content-[''] " +
                  (url === '/callback' ? activeLinkClassname : '')
                }
              >
                Σε Καλούμε
              </a>
            </li>

            <li>
              <a
                href="https://www.hhg.gr/el/επικοινωνία"
                target="_blank"
                className="relative transition-all hover:before:absolute hover:before:-bottom-3 hover:before:left-1/2 hover:before:h-1 hover:before:w-full hover:before:translate-x-[-50%] hover:before:rounded-full hover:before:bg-primary hover:before:content-[''] "
                rel="noreferrer"
              >
                Επικοινωνία
              </a>
            </li>

            {isLoggedIn && (
              <li>
                <a
                  href="/account"
                  target="_self"
                  className={
                    "relative transition-all hover:before:absolute hover:before:-bottom-3 hover:before:left-1/2 hover:before:h-1 hover:before:w-full hover:before:translate-x-[-50%] hover:before:rounded-full hover:before:bg-primary hover:before:content-[''] " +
                    (url === '/account' ? activeLinkClassname : '')
                  }
                >
                  Προφίλ
                </a>
              </li>
            )}
          </ul>

          {isLoggedIn ? (
            <button
              onClick={handleLogout}
              type="button"
              className="inline-flex items-center rounded-full border border-primary bg-primary px-6 py-4 text-center text-base font-medium text-gray-50 transition-all hover:bg-gray-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              <span>Αποσύνδεση</span>
              <LogOutIcon className="ml-2 h-6 w-6" />
            </button>
          ) : (
            <a
              href="/login"
              className="inline-flex items-center rounded-full border border-primary bg-gray-50 px-6 py-4 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              <span>Είσοδος Χρήστη</span>
              <ManIcon />
            </a>
          )}
        </div>

        {isOpen && (
          <MobileMenu
            onClick={() => setIsOpen(!isOpen)}
            isLoggedIn={isLoggedIn}
            logout={logout}
          />
        )}
      </nav>
    </header>
  );
}
