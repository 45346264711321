import { ErrorMessage, Formik, Form, Field, FormikValues } from 'formik';
import * as Yup from 'yup';

import { NextStepIcon, TickIcon } from 'components/svgs';
import request from 'request';
import { useState } from 'react';

interface PasswordField {
  email: string;
}
const NewPassword = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Εισάγετε μια έγκυρη διεύθυνση email')
      .required('Εισάγετε το email σας'),
  });

  const handleSubmit = async (
    values: PasswordField,
    { setFieldError }: FormikValues,
  ) => {
    try {
      const response = await request.post(
        '/api/v1/auth/reset-password/',
        values,
      );

      if (response.status === 200) {
        setShowSuccessMessage(true);
      }
    } catch (err) {
      console.log(err);
      setFieldError(
        'email',
        'Ουπς, κάτι πήγε στραβά! Παρακαλώ προσπαθήστε αργότερα.',
      );
    }
  };

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-2xl rounded-3xl bg-gray-50 px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <h1 className="mb-6 text-2xl font-bold md:text-3xl lg:text-4xl">
          Ξεχάσατε τον κωδικό σας;
        </h1>
        <p className="mx-auto mb-6 max-w-md text-xl">
          {' '}
          Συμπληρώστε το email σας και θα σας αποσταλεί σύνδεσμος για να
          δημιουργήσετε νέο κωδικό.
        </p>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="mx-auto max-w-md text-left">
              <div className="mb-6 text-left">
                <label
                  htmlFor="email"
                  className="mb-1.5 block text-sm text-gray-900 md:text-base"
                >
                  EMAIL
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  data-test="new-password-email-field"
                  className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                  required
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="mt-2 text-xs text-red-600"
                  data-test="new-password-email-error"
                />
              </div>
              <div className="flex w-full flex-row items-center justify-center text-lg md:text-xl">
                <p>Δεν έχετε λογαριασμό; Εγγραφείτε</p> &nbsp;{' '}
                <a
                  href="/register/"
                  className="font-bold text-primary hover:text-gray-600"
                >
                  εδώ
                </a>
              </div>
              <a
                href="/login/"
                className="mx-auto my-6 block w-full text-center text-lg  font-bold text-primary hover:text-gray-600 md:text-xl"
              >
                Επιστροφή στη σύνδεση
              </a>

              <div className="w-full text-center">
                <button
                  data-test="new-password-submit-button"
                  type="submit"
                  disabled={isSubmitting}
                  className="inline-flex items-center rounded-full border border-primary bg-primary px-6  py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                >
                  {isSubmitting ? 'Αναμονη...' : 'Υποβολή'}
                  <NextStepIcon className="ml-2.5 h-6 w-6" />
                </button>
              </div>
              {showSuccessMessage && (
                <div
                  className="mt-10 flex flex-col items-center justify-center gap-2 text-center text-base"
                  data-test="new-password-success-message"
                >
                  <span>
                    Αν υπάρχει το email, θα λάβετε οδηγίες για την επαναφορά του
                    κωδικού σας.
                  </span>
                  <TickIcon className="h-14 w-14 text-green-600" />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
};

export default NewPassword;
