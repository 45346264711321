import confirmationImg from 'img/confirmationImg.svg';
import { ArrowBackIcon, TickIcon } from 'components/svgs';

const ConfirmationMsg = () => {
  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mb-10 flex flex-col items-center gap-6 rounded-3xl px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <div className="mt-10 grid w-full grid-cols-3">
          <a
            href="/"
            className="h-10 w-10 cursor-pointer self-start text-blue-600 hover:text-gray-600"
          >
            <ArrowBackIcon />
          </a>
          <div className="h-11 w-11 justify-self-center text-green-600">
            <TickIcon />
          </div>
        </div>
        <h1 className="mb-16 max-w-xl text-2xl font-bold md:text-3xl lg:mb-20 lg:text-4xl">
          Έχουμε λάβει το μήνυμα σας και θα σας καλέσουμε σύντομα!
        </h1>
        <img src={confirmationImg} alt="confirmationImage" />
      </div>
    </main>
  );
};

export default ConfirmationMsg;
