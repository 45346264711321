import { AdditionIcon } from 'components/svgs';

interface Props {
  isAddAppointmentButtonDisabled: boolean;
  onOpenServiceSelectForm: () => void;
}

export default function AddNewAppointment({
  isAddAppointmentButtonDisabled,
  onOpenServiceSelectForm,
}: Props) {
  return (
    <section
      data-aos="fade-in"
      data-aos-once="true"
      data-aos-duration="1500"
      className="group mb-10 flex cursor-pointer flex-col items-center justify-center space-y-4 rounded-3xl bg-gray-50 px-4 py-10 text-center transition-all print:hidden lg:text-left xl:px-14 2xl:px-20"
    >
      <h1 className="text-lg font-bold md:text-xl lg:text-2xl">
        Προσθέστε νέο ραντεβού
      </h1>

      <button
        disabled={isAddAppointmentButtonDisabled}
        onClick={onOpenServiceSelectForm}
        className="h-12 w-12 text-blue-500 transition-all disabled:text-gray-200 group-hover:scale-125 group-hover:text-blue-900 disabled:group-hover:text-gray-200"
      >
        <AdditionIcon />
      </button>

      {isAddAppointmentButtonDisabled && (
        <p className="max-w-lg text-center">
          Μόλις αποθηκεύσετε το προηγούμενο ραντεβού μπορείτε να προγραμματίσετε
          το επόμενο σας ραντεβού
        </p>
      )}
    </section>
  );
}
