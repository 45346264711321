import { useState, useEffect, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { startDate, endDate } from 'utils/consts';
import useStore from 'store';
import useStoreServices from 'storeServices';
import { Select } from 'components/Select';
import validationSchema from 'utils/validationSchema';
import DateTimeSelect from './DateTimeSelect';
import Service from './Service';
import NoDigitalAppointment from 'components/NoDigitalAppointment';
import Appointment from '../../components/Appointment';
import AddNewAppointment from '../../components/AddNewAppointment';
import NavigationButtons from '../../components/NavigationButtons';
import { Loading } from 'components/Loader';
import servicesIcon from 'img/services.svg';
import simpleDoctor from 'img/simple-doctor.svg';
import building from 'img/building.svg';
import { CloseIcon, ExclamationMark } from 'components/svgs';
import myAppointmentsCalendar from 'img/myAppointmentsCalendar.png';
import { ReactComponent as MagnifyingLens } from 'img/magnifying-lens.svg';
import { getCustomizations, getServices } from 'helpers';
import { startOfDay, endOfDay, lastDayOfMonth } from 'date-fns';

import {
  AppointmentType,
  PointsType,
  ServiceGroupType,
  ServiceType,
  InitialFormType,
  PolicyType,
  AvailabilityHours,
  ServiceTypeEnum,
} from 'types';

import {
  useCheckAvailability,
  useStatuses,
  useServicesData,
  useArrivalPolicies,
  useAvailabilityHours,
} from 'api';
import useScrollTo from 'hooks/useScrollTo';
import { isEmpty } from 'lodash';
import { default as Error } from 'views/reservation/components/ErrorMessage';

export default function Reservations() {
  const navigate = useNavigate();

  const [addNewApp, setAddNewApp] = useState(false);
  const [showNoAvailableAppointments, setShowNoAvailableAppointments] =
    useState(false);

  const {
    appointments,
    clearAppointments,
    setAppointment,
    setInstructionModalText,
    setRemovalModalState,
    setAddAttachmentModalState,
    setIsAppsExpired,
    currentAppointment,
    setCurrentAppointment,
    isDatetimeRangeOk,
  } = useStore();

  const { availableArrivalPolicies, setAvailableArrivalPolicies } =
    useStoreServices();

  const [isServiceSelectFormOpen, setIsServiceSelectFormOpen] = useState(
    Boolean(currentAppointment),
  );

  const [isCalendarOpen, setIsCalendarOpen] = useState(
    Boolean(currentAppointment),
  );
  const [serviceDescription, setServiceDescription] = useState(false);
  const [digitalAppointment, setDigitalAppointment] = useState(true);
  const [noDigitalAppointmentServiceType, setNoDigitalAppointmentServiceType] =
    useState<ServiceTypeEnum>();
  const [selectedService, setSelectedService] = useState<ServiceType>();
  const [pointContactInfo, setPointContactInfo] = useState<string>();

  const [availHoursStartDay, setAvailHoursStartDay] = useState<Date>(startDate);
  const [availHoursEndDay, setAvailHoursEndDay] = useState<Date>(endDate);

  const { scrollToElement } = useScrollTo();

  const queryClient = useQueryClient();

  const { data, error } = useServicesData();
  const {
    data: policiesData,
    error: policiesError,
    isFetching: policiesIsFetching,
  } = useArrivalPolicies();

  const {
    data: availabilityHours,
    isInitialLoading,
    isFetched,
  } = useAvailabilityHours(
    currentAppointment ?? undefined,
    availHoursStartDay,
    availHoursEndDay,
  );

  const { data: statusesList } = useStatuses();
  const { mutateAsync: checkAvailability } = useCheckAvailability();

  const selectedValues = [{ serviceGrp: {}, service: {}, point: {} }];

  const memoizedAvailHours = useMemo(() => {
    if (!availabilityHours) {
      return {};
    }
    return Object.fromEntries(
      Object.entries(availabilityHours).filter(
        ([_, entry]) => entry.length > 0,
      ),
    );
  }, [availabilityHours]);

  const initialValues: InitialFormType | {} = {
    serviceGrp: addNewApp ? {} : currentAppointment?.serviceGrp ?? {},
    service: addNewApp ? {} : currentAppointment?.service ?? {},
    point: addNewApp ? {} : currentAppointment?.point ?? {},
    id: currentAppointment?.id ?? undefined,
  };

  const handleUpdateDateTime = () => {
    setCurrentAppointment({
      start: startDate,
      end: endDate,
    });
  };

  const handleMonthChange = async (activeStartDate: Date | null) => {
    try {
      const startDay =
        activeStartDate != null && activeStartDate > new Date()
          ? activeStartDate
          : new Date();
      const endDay = lastDayOfMonth(startDay);

      setAvailHoursStartDay(startOfDay(startDay));
      setAvailHoursEndDay(endOfDay(endDay));
    } catch (error) {
      console.error('Error while refreshing data:', error);
    }
  };

  const handleSubmitDateTime = async (values: InitialFormType) => {
    let customization = getCustomizations(values.service, values.point);
    const isServiceOnline =
      customization !== undefined
        ? customization.service_type === ServiceTypeEnum.CALL_N_ONLINE
        : values.service.service_type === ServiceTypeEnum.CALL_N_ONLINE;
    if (!isServiceOnline) {
      setDigitalAppointment(false);
      setNoDigitalAppointmentServiceType(
        customization !== undefined
          ? customization.service_type
          : values.service.service_type,
      );
    } else {
      setDigitalAppointment(true);
      setIsCalendarOpen(true);
      setAddNewApp(false);
      values.service.guidelines === ''
        ? setServiceDescription(false)
        : setServiceDescription(true);
      setInstructionModalText(values.service.guidelines);
      setCurrentAppointment({
        service: values.service,
        point: values.point,
      });
      selectedValues.some((stateValues) => {
        return (
          values.serviceGrp !== stateValues.serviceGrp ||
          values.service !== stateValues.service ||
          values.point !== stateValues.point
        );
      }) &&
        !addNewApp &&
        setShowNoAvailableAppointments(true);
    }
  };

  const handleCheckAppointment = async (appointment: AppointmentType) => {
    const prebookedStatus = statusesList?.find(
      (item) => item.status_type === 'PREBOOKED',
    );
    const reserveAppointment = {
      ...appointment,
      status: prebookedStatus,
    } as AppointmentType;

    try {
      let availabilityRes: AppointmentType & { status: string } =
        await checkAvailability(reserveAppointment);

      setAppointment(availabilityRes);

      setIsServiceSelectFormOpen(false);
    } catch (error) {
      console.log('Ούπς! Κάτι πήγε στραβα!', error);

      setDigitalAppointment(true);
    } finally {
      setDigitalAppointment(true);
    }
  };

  const handleAppsExpired = () => {
    setIsAppsExpired(true);
    clearAppointments();
    navigate('/');
  };

  const handleReserveAppointments = async () => {
    try {
      navigate('/new/user-input');
    } catch (error) {
      console.log('Ούπς! Κάτι πήγε στραβα!', error);
      handleAppsExpired();
    }
  };

  const availableReserveHours: AvailabilityHours = memoizedAvailHours ?? [];
  const serviceGrp: ServiceGroupType[] = data?.serviceGrp ?? [];
  const services: ServiceType[] = data?.services ?? [];
  const points: PointsType[] = data?.points ?? [];

  useEffect(() => {
    !policiesIsFetching &&
      isEmpty(availableArrivalPolicies) &&
      setAvailableArrivalPolicies(policiesData as PolicyType[]);
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    isEmpty(availabilityHours) && setShowNoAvailableAppointments(true);
    addNewApp && setShowNoAvailableAppointments(false);
  }, [availabilityHours, addNewApp]);

  if (!isDatetimeRangeOk) {
    scrollToElement('DateTimeError');
  }

  return (
    <>
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="print:hidden"
      >
        <h1 className="md:max-w-base mx-auto mb-6 max-w-xs text-center text-xl font-bold sm:mb-10 sm:max-w-md sm:text-2xl lg:max-w-xl lg:text-3xl xl:max-w-3xl">
          Επιλέξτε την ημερομηνία και ώρα που σας εξυπηρετεί για κάθε ραντεβού
          σας
        </h1>
      </div>

      {!isDatetimeRangeOk && (
        <div
          id="DateTimeError"
          className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl"
        >
          <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
            <ExclamationMark />
          </div>
          <p className="text-left text-xs md:text-base">
            Οι ημερομηνίες και ώρες των ραντεβού σας αλληλεπικαλύπτονται
          </p>
        </div>
      )}

      {appointments.length > 0 && (
        <>
          {appointments.map((appointment: AppointmentType) => {
            return (
              <Appointment
                key={crypto.randomUUID()}
                appointment={appointment}
                policies={availableArrivalPolicies}
                onDelete={() => {
                  setRemovalModalState({ isOpen: true, appointment });
                }}
                onAddAttachment={() => {
                  setAddAttachmentModalState({ isOpen: true, appointment });
                }}
                onEdit={() => {
                  setCurrentAppointment(appointment);

                  setIsServiceSelectFormOpen(true);
                  setIsCalendarOpen(true);
                }}
              />
            );
          })}
        </>
      )}

      {isServiceSelectFormOpen && (
        <section
          data-aos="fade-in"
          data-aos-once="true"
          data-aos-duration="1500"
          className="mb-10 rounded-3xl bg-gray-50 px-4 py-10 text-center lg:text-left xl:px-14 2xl:px-20"
        >
          <div className="flex w-full justify-between">
            {serviceDescription ? (
              <Service service={currentAppointment?.service?.name!} />
            ) : (
              <h1 className="mb-6 text-xl font-bold md:mb-10 md:text-3xl lg:text-2xl">
                Επιλογή Υπηρεσίας
              </h1>
            )}
            {appointments.length > 0 && (
              <div className="mb-6 flex flex-row justify-center md:mb-10 lg:justify-end">
                <button
                  type="button"
                  className="inline-flex cursor-pointer items-start rounded-full px-6 py-3.5 text-center text-lg font-semibold text-gray-500"
                  data-test="remove-appointment-services"
                  onClick={() => {
                    setCurrentAppointment(undefined);
                    setServiceDescription(false);
                    setAddNewApp(false);
                    setIsCalendarOpen(false);
                    setIsServiceSelectFormOpen(false);
                  }}
                >
                  <CloseIcon className="ml-2.5 h-8 w-8" />
                </button>
              </div>
            )}
          </div>

          <Formik
            initialValues={initialValues as InitialFormType}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmitDateTime}
            id="DropdownsForm"
          >
            {({ isSubmitting, setFieldValue }) => {
              return (
                <Form>
                  <div className="md:grid-rows-0 mx-auto grid max-w-md grid-rows-1 lg:max-w-full lg:grid-cols-3 lg:space-x-5">
                    <div
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                      aria-label="Select Service"
                      className="relative mt-2 rounded-md shadow-sm"
                    >
                      <Field
                        as={Select}
                        title="Ομάδα υπηρεσιών"
                        name="serviceGrp"
                        serviceGrp={serviceGrp}
                        servicesIcon={servicesIcon}
                        handleOnChange={(item: ServiceGroupType) => {
                          handleUpdateDateTime(); // Pass the current date to the function
                          setIsCalendarOpen(false);
                          setFieldValue('serviceGrp', item);
                          setFieldValue('service', '');
                          setFieldValue('point', '');
                          setDigitalAppointment(true);
                          selectedValues[0].serviceGrp = { ...item };
                          setShowNoAvailableAppointments(false);
                          setCurrentAppointment({
                            serviceGrp: item,
                            service: undefined,
                          });
                        }}
                      />
                    </div>
                    <div
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-delay="400"
                      data-aos-duration="1000"
                      className="relative mt-2 rounded-md shadow-sm"
                      aria-label="Select Service"
                    >
                      <Field
                        as={Select}
                        title="Υπηρεσία"
                        name="service"
                        serviceGrp={getServices(
                          services,
                          currentAppointment?.serviceGrp,
                        )}
                        servicesIcon={simpleDoctor}
                        handleOnChange={(item: ServiceType) => {
                          queryClient.resetQueries(['availableSlots']);
                          handleUpdateDateTime();
                          setFieldValue('service', item);
                          setSelectedService(item);
                          setIsCalendarOpen(false);
                          selectedValues[0].service = { ...item };
                          setShowNoAvailableAppointments(false);
                          setDigitalAppointment(true);
                        }}
                      />
                    </div>
                    <div
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-delay="600"
                      data-aos-duration="1000"
                      className="relative mt-2 rounded-md shadow-sm"
                      aria-label="Select Service"
                    >
                      <Field
                        as={Select}
                        title="Σημείο εξυπηρέτησης"
                        name="point"
                        serviceGrp={points}
                        servicesIcon={building}
                        handleOnChange={(item: PointsType) => {
                          setFieldValue('point', item);
                          setIsCalendarOpen(false);
                          setDigitalAppointment(true);
                          handleUpdateDateTime();
                          selectedValues[0].point = { ...item };
                          setPointContactInfo(item.contact_information);
                          setShowNoAvailableAppointments(false);
                        }}
                      />
                    </div>
                  </div>

                  {(!!error || !!policiesError) && (
                    <div>
                      <Error message="Υπήρξε πρόβλημα με την ανάκτηση των δεδομένων. Παρακαλούμε δοκιμάστε αργότερα. " />
                    </div>
                  )}

                  <ErrorMessage
                    name="serviceGrp"
                    render={(msg) => (
                      <div className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl">
                        <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
                          <ExclamationMark />
                        </div>
                        <p className="text-left text-xs md:text-base">{msg}</p>
                      </div>
                    )}
                  />
                  <ErrorMessage
                    name="service"
                    render={(msg) => (
                      <div className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl">
                        <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
                          <ExclamationMark />
                        </div>
                        <p className="text-left text-xs md:text-base">{msg}</p>
                      </div>
                    )}
                  />
                  <ErrorMessage
                    name="point"
                    render={(msg) => (
                      <div className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl">
                        <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
                          <ExclamationMark />
                        </div>
                        <p className="text-left text-xs md:text-base">{msg}</p>
                      </div>
                    )}
                  />

                  {!digitalAppointment && (
                    <NoDigitalAppointment
                      pointContactInformation={pointContactInfo as string}
                      noDigitalAppointmentServiceType={
                        noDigitalAppointmentServiceType as ServiceTypeEnum
                      }
                    />
                  )}

                  <div
                    data-aos="zoom-in"
                    data-aos-once="true"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    className="mt-6 flex w-full flex-row justify-center lg:justify-end"
                  >
                    <button
                      disabled={!!error || isSubmitting}
                      type="submit"
                      className="inline-flex cursor-pointer items-center rounded-full border-2 border-primary bg-primary px-6 py-3.5 text-center text-lg font-semibold text-white transition-all hover:bg-gray-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
                      data-test="submit-appointment-services"
                    >
                      {isSubmitting ? 'Αναζήτηση' : 'Αναζήτηση'}
                      <MagnifyingLens className="ml-2.5 h-6 w-6" />
                    </button>
                  </div>

                  <div
                    data-aos="zoom-in"
                    data-aos-once="true"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    className="mt-6 flex w-full flex-row justify-center md:justify-end"
                  >
                    {isInitialLoading && (
                      <div className="me-20 mt-6 flex w-full flex-row justify-end">
                        <Loading size={120} />
                      </div>
                    )}
                    {isCalendarOpen &&
                    Object.keys(availableReserveHours).length > 0 &&
                    !isInitialLoading ? (
                      <DateTimeSelect
                        currentAppointment={currentAppointment}
                        onMonthChange={handleMonthChange}
                        availabilityHours={availableReserveHours}
                        onStateChange={() => {
                          handleCheckAppointment(currentAppointment!);
                          setIsCalendarOpen(false);
                          setServiceDescription(false);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
          {isFetched &&
            Object.keys(availableReserveHours).length === 0 &&
            (currentAppointment?.service ?? selectedService) &&
            currentAppointment?.point &&
            showNoAvailableAppointments && (
              <div className="flex flex-col items-center text-center">
                <div className="grid w-full  items-center ">
                  <div>
                    <img
                      className="mx-auto"
                      src={myAppointmentsCalendar}
                      alt="myAppointmentsCalendar"
                    />
                    <p className="mt-10">
                      Δεν υπάρχουν διαθεσιμα ραντεβού για το διάστημα που
                      επιλέξατε.
                    </p>
                  </div>
                </div>
              </div>
            )}
        </section>
      )}

      <AddNewAppointment
        onOpenServiceSelectForm={() => {
          setIsCalendarOpen(false);
          setAddNewApp(true);
          setIsServiceSelectFormOpen(true);
          scrollToElement('DropdownsForm');
          setCurrentAppointment(undefined);
        }}
        isAddAppointmentButtonDisabled={isServiceSelectFormOpen}
      />

      <NavigationButtons
        isForwardButtonDisabled={
          appointments.length === 0 || !isDatetimeRangeOk
        }
        isPageUserReservationPreview={false}
        onPreviousStep={() => {
          navigate(-1);
        }}
        onForwardStep={() => {
          handleReserveAppointments();
          setCurrentAppointment(undefined);
        }}
      />
    </>
  );
}
