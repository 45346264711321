import { ExclamationMark } from 'components/svgs';

const msg = 'O χρόνος κράτησης 15 λεπτών έληξε. Παρακαλώ επιλέξτε ξανά';

export default function ExpiredMsg() {
  return (
    <div className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl">
      <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
        <ExclamationMark />
      </div>
      <p className="text-left text-xs md:text-base">{msg}</p>
    </div>
  );
}
