import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import {
  CheckIcon,
  ConfirmIcon,
  ReservationIcon,
  ReviewIcon,
  UserIcon,
} from 'components/svgs';

import { StepEnum } from 'types';

const steps = [
  { value: 'Έναρξη', name: StepEnum.BEGIN, img: CheckIcon, url: '/new' },
  {
    value: 'Κρατήσεις',
    name: StepEnum.RESERVATIONS,
    img: ReservationIcon,
    url: '/new/reservations',
  },
  {
    value: 'Στοιχεία',
    name: StepEnum.USER_INPUT,
    img: UserIcon,
    url: '/new/user-input',
  },
  {
    value: 'Επισκόπηση',
    name: StepEnum.RESERVATIONS_PREVIEW,
    img: ReviewIcon,
    url: '/new/reservations-preview',
  },
  {
    value: 'Ολοκλήρωση',
    name: StepEnum.RESERVATIONS_CONFIRM,
    img: ConfirmIcon,
    url: '/new/reservations-confirm',
  },
];

export default function Stepper() {
  const { pathname } = useLocation();

  return (
    <ol className="mx-auto mb-14 flex w-full max-w-xl items-center px-4 print:hidden sm:mb-20 sm:px-0 md:max-w-2xl lg:max-w-5xl">
      {steps.map((item, index) => {
        const currIndex = steps.findIndex((item) => item.url === pathname);
        const isComplete = currIndex > index || index === 0;
        const isFinished = currIndex === steps.length - 1;
        const isCurrent = currIndex === index;
        return (
          <li
            key={item.value}
            data-aos="zoom-in-right"
            data-aos-once="true"
            data-aos-duration="1000"
            className={cn(
              'step-item',
              isFinished
                ? 'step-item-complete'
                : isComplete && 'active-step-line',
            )}
          >
            <div className="relative flex flex-col text-gray-500">
              {isFinished ? (
                <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-green-700 sm:h-10 sm:w-10 lg:h-12 lg:w-12">
                  <CheckIcon className="h-3 w-3 text-white sm:h-5 sm:w-5 lg:h-6 lg:w-6" />
                </span>
              ) : (
                <span
                  className={`flex h-6 w-6 shrink-0 items-center justify-center rounded-full sm:h-10 sm:w-10 lg:h-12 lg:w-12 ${
                    isComplete && 'active-step'
                  } ${isCurrent ? 'bg-primary text-white' : 'bg-gray-200'}`}
                >
                  {isComplete ? (
                    <CheckIcon className="h-3 w-3 bg-primary text-white sm:h-5 sm:w-5 lg:h-6 lg:w-6" />
                  ) : (
                    <item.img className="h-3 w-3 sm:h-5 sm:w-5 lg:h-6 lg:w-6" />
                  )}
                </span>
              )}
              <p className="absolute -bottom-5 left-1/2 mx-auto -translate-x-1/2 transform text-center text-[11px] tracking-tight text-gray-900 sm:-bottom-6 sm:text-base sm:tracking-normal lg:-bottom-8">
                {item.value}
              </p>
            </div>
          </li>
        );
      })}
    </ol>
  );
}
